<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">

        <v-row class="py-3 pb-5" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Data Order</h2>
          </v-card-title>
          <v-card-title>
            <!-- Search data -->
            <v-text-field append-icon="mdi-magnify" :items="orders" item-value="id" :search-input.sync="searchOrder"
              label="Search" v-model="search" single-line hide-details class="font-weight-light"></v-text-field>
            <v-btn rounded elevation="2" color="success" dark small
              class="text-capitalize font-weight-light white--text ml-5" @click="getSearchData">
              Search
            </v-btn>
            <!-- Add Button -->
            <v-btn rounded elevation="2" color="info" dark small
              class="text-capitalize font-weight-light white--text ml-5" :to="{name: 'admin-order-create'}">
              Add
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Table -->
        <div>

          <v-simple-table fixed-header>
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Perusahaan</th>
                <th>Start Period</th>
                <th>End Period</th>
                <th>Created On</th>
                <th>Packages</th>
                <th>Invoice Number</th>
                <th>Canceled</th>
                <th style="width: 150px;">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in orders.data" :key="data.id">
                <td>{{ ((page-1)*10)+index+1 }}</td>
                <td>{{ data.company ? data.company.name_company : '' }}</td>
                <td>{{ data.start_period ? data.start_period : '' }}</td>
                <td>{{ data.end_period ? data.end_period : '' }}</td>
                <td>{{ formatDate(data.created_on) }}</td>
                <td>{{ data.packages ? data.packages.name : '' }}</td>
                <td>{{ data.invoice_number }}</td>
                <td>
                  <v-btn v-show="data.is_canceled == 0" rounded elevation="2" color="success" dark small
                    class="text-capitalize font-weight-light white--text">
                    NO
                  </v-btn>
                  <v-btn v-show="data.is_canceled == 1" rounded elevation="2" color="error" dark small
                    class="text-capitalize font-weight-light white--text">
                    YES
                  </v-btn>
                </td>
                <td>
                  <!-- upload file -->
                  <v-dialog v-if="data.payment_proof == null || data.payment_proof == ''" :retain-focus="false"
                    transition="dialog-top-transition" max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon color="grey" v-show="data.is_canceled == 0" elevation="0" small class="text-capitalize font-weight-light"
                        v-bind="attrs" v-on="on">
                        <v-icon>mdi-file-upload-outline</v-icon>
                      </v-btn>
                    </template>

                    <!-- temp view order -->
                    <template>
                      <v-card>
                        <!-- Header modal -->
                        <v-toolbar color="primary" dark>Upload Bukti Bayar
                        </v-toolbar>

                        <!-- Modal content -->
                        <v-card-text class="mt-5">
                          <v-row align="center">
                            <v-col cols="12">
                              <v-file-input v-model="file"  label="Choose File" ref="file" accept="image/*" show-size truncate-length="15">
                              </v-file-input>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- Modal footer (button) -->
                        <v-card-actions class="justify-end">


                          <!-- if published -->
                          <v-btn class="text-capitalize font-weight-light" color="info" elevation="0"
                            @click="uploadFile(data.id)">
                            <v-icon right dark>
                              mdi-upload
                            </v-icon>
                            Upload
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>


                  </v-dialog>
                  <!-- end -->
                  
                  <v-btn v-show="data.is_canceled == 0" v-if="data.payment_proof == null || data.payment_proof == ''" dark icon elevation="0" small
                    color="warning" class="text-capitalize font-weight-light"
                    :to="{name: 'admin-order-edit', params: { id: data.id }}">
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                  
                  <v-btn dark icon elevation="0" small color="error" class="text-capitalize font-weight-light"
                    @click="confirm(data)">
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                  
                  <v-dialog transition="dialog-top-transition" max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon color="grey" elevation="0" small class="text-capitalize font-weight-light"
                        v-bind="attrs" v-on="on">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>

                    <!-- temp view order -->
                    <template>
                      <v-card>
                        <!-- Header modal -->
                        <v-toolbar color="primary" dark>Data Order
                        </v-toolbar>

                        <!-- Modal content -->
                        <v-card-text class="mt-5">
                          <v-row align="center">

                            <v-col cols="4">
                              <v-text-field label="Company" dense outlined readonly
                                :value="data.company ? data.company.name_company : ''"></v-text-field>
                            </v-col>

                           <v-col cols="3">
                             <v-text-field label="User Packages" dense outlined readonly
                               :value="data.userPackages ? data.userPackages.name : ''"></v-text-field>
                           </v-col>

                            <v-col cols="3">
                              <v-text-field label="Packages" dense outlined readonly
                                            :value="data.packages ? data.packages.name : ''"></v-text-field>
                            </v-col>

                            <v-col cols="2">
                              <v-text-field label="Total user" dense outlined readonly v-model="data.total_user">
                              </v-text-field>
                            </v-col>

                            <v-col cols="3">
                              <v-text-field label="Start Period" dense outlined readonly
                                :value="ampmTime(data.start_period)"></v-text-field>
                            </v-col>

                            <v-col cols="3">
                              <v-text-field label="End Period" dense outlined readonly
                                :value="ampmTime(data.end_period)"></v-text-field>
                            </v-col>

                            <v-col cols="3">
                              <v-text-field label="Invoice Number" dense outlined readonly :value="data.invoice_number">
                              </v-text-field>
                            </v-col>

                            <v-col cols="3">
                              <v-text-field label="Remarks" dense outlined readonly :value="data.remarks">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12">
                              <label>Extra Package :</label>
                              <v-list-item v-for="(item, k) in data.extra_package" :key="k">
                                <v-list-item-content>
                                  <v-row>
                                    <v-col cols="4">
                                      {{item.name_extra_package}}
                                    </v-col>
                                    <v-col cols="4">
                                      Rp. {{item.price_extra_package}}
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>

                            <v-col cols="12">
                              <label>Payment Proof :</label>
                              <v-dialog transition="dialog-top-transition" max-width="800">
                                  <!-- <v-tooltip bottom color="warning"> -->

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-img :lazy-src="data.image_payment_proof" :src="data.image_payment_proof" v-bind="attrs" v-on="on">
                                        </v-img>
                                        <!-- <span>Click for reupload</span> -->
                                    </template>
                                  <!-- </v-tooltip> -->

                                <template>
                                  <v-card>
                                    <!-- Header modal -->
                                    <v-toolbar color="primary" dark>Edit Bukti Bayar
                                    </v-toolbar>

                                    <!-- Modal content -->
                                    <v-card-text class="mt-5">
                                      <v-row align="center">
                                        <v-col cols="12">
                                          <v-file-input v-model="file"  label="Choose File" ref="file" accept="image/*" show-size truncate-length="15">
                                          </v-file-input>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>

                                    <!-- Modal footer (button) -->
                                    <v-card-actions class="justify-end">
                                      <!-- if published -->
                                      <v-btn class="text-capitalize font-weight-light" color="info" elevation="0"
                                        @click="uploadFile(data.id)">
                                        <v-icon right dark>
                                          mdi-upload
                                        </v-icon>
                                        Upload
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </v-col>
                            <!-- <v-col cols="12">
                                {{ data.add_on }}
                            </v-col> -->
                          </v-row>
                        </v-card-text>

                        <!-- Modal footer (button) -->
                        <v-card-actions class="justify-end">
                          <!-- if unpublished -->
                          <v-btn v-if="data.status == 0" class="text-capitalize font-weight-light" color="primary"
                            elevation="0" @click="onChangeStatus(data)">
                            <v-icon right dark>
                              mdi-refresh
                            </v-icon>
                            publish
                          </v-btn>

                          <!-- if published -->
                          <v-btn v-if="data.status == 1" class="text-capitalize font-weight-light" color="error"
                            elevation="0" @click="onChangeStatus(data)">
                            <v-icon right dark>
                              mdi-refresh
                            </v-icon>
                            unpublish
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>


                  </v-dialog>
                  <v-btn dark icon elevation="0" v-show="(data.is_canceled == 0 && data.is_billing == 0 && data.is_auto_order == 0)" small color="blue darken-4" class="text-capitalize font-weight-light"
                    @click="download_inv(data)">
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                  <v-btn dark icon elevation="0" v-show="data.is_canceled == 0 && data.is_billing == 1" small color="blue darken-4" class="text-capitalize font-weight-light"
                    @click="download_inv_flexmod(data)">
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                  <v-btn dark icon elevation="0" v-show="(data.is_canceled == 0 && data.is_billing == 0 && data.is_auto_order == 1)" small color="blue darken-4" class="text-capitalize font-weight-light"
                    @click="download_inv_reorder(data)">
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                  <v-btn dark icon elevation="0" v-show="data.is_canceled == 0" small color="blue darken-4" class="text-capitalize font-weight-light"
                    @click="send_inv(data)">
                    <v-icon>mdi-send mdi-rotate-315</v-icon>
                  </v-btn>
                  <v-btn v-show="data.is_canceled == 0 && (data.payment_proof == null || data.payment_proof == '')" dark icon elevation="0" small color="red darken-4" class="text-capitalize font-weight-light"
                    @click="confirmCancel(data)">
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div class="text-right pb-10 mt-5">
            <v-pagination v-model="page" :length="totalPage" @change="getData()" prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"></v-pagination>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
  import {
    mapActions,
    mapState
  } from "vuex";
  // import axios from "axios";

  export default {
    data() {
      return {
        page: 1,
        // speed dial button
        dialog: false,
        hover: 'false',
        fab: false,
        file: null,
        direction: 'right',
        transition: 'slide-x-transition',
        breads: [{
            text: 'Order',
            disabled: false,
            href: 'orders',
          },
          {
            text: 'Creat order',
            disabled: false,
            href: 'orders/create',
          },
        ],
        search: '',
        searchOrder: '',
        number: 0,
        listStatus: [{
          id: 0,
          name: 'new'
        }, {
          id: 1,
          name: 'paid'
        }, {
          id: 2,
          name: 'suspend'
        }]
      }
    },
    computed: {
      ...mapState("order", ["orders"]),
      ...mapState("company", ["companies"]),
      totalPage: function () {
        if (typeof this.orders.meta !== "undefined")
          return this.orders.meta.last_page
        return 1
      }
    },
    watch: {
      page: function () {
        this.getData()
      },
    },
    methods: {
      ...mapActions(["removeErrors"]),
      ...mapActions("order", ["getOrders", "deleteOrder", "getPaymentProof", "sendInv", "cancelOrder"]),
      ampmTime(date) {
        let formatDate = new Date(date);
        return formatDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
      },
      confirm(data) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            this.deleteOrder(data.id);
            this.$swal("Deleted!", "Item Deleted", "success");
          }
        });
      },
      confirmCancel(data) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            this.cancelOrder(data)
              .then((res) => {
                console.log(res)
                this.getData()
              })
            // this.$swal("Deleted!", "Item Deleted", "success");
          }
        });
      },
      download_inv(data){
        // console.log(data)
        // console.log(window.axios.defaults.baseURL)
              // let config = {
              //   headers: {
              //     Authorization: "Bearer " + localStorage.getItem("token")
              //   }
              // }
              // axios({
              //   url: window.axios.defaults.baseURL + 'orders/pdf/' + data.id, config,
              //   method: 'GET',
              //   responseType: 'blob',
              // }).then((response) => {
              //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              //   var fURL = document.createElement('a');

              //   fURL.href = fileURL;
              //   fURL.setAttribute('download', 'Invoice ' + data.company.name_company + '.pdf');
              //   document.body.appendChild(fURL);

              //   fURL.click();
              // });

              window.location.href = "https://dummy.oaktree.id/admin/pdf/" + data.id
              // axios({
              //       url: 'https://localhost:8000/orders/pdf/' + data.id, // File URL Goes Here
              //       method: 'GET',
              //       responseType: 'blob',
              //   }).then((res) => {
              //        var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
              //        var docUrl = document.createElement('x');
              //        docUrl.href = FILE;
              //        docUrl.setAttribute('download', 'file.pdf');
              //        document.body.appendChild(docUrl);
              //        docUrl.click();
              //   });
      },
      download_inv_flexmod(data){
        // console.log(data)
        // console.log(window.axios.defaults.baseURL)
              // let config = {
              //   headers: {
              //     Authorization: "Bearer " + localStorage.getItem("token")
              //   }
              // }
              // axios({
              //   url: window.axios.defaults.baseURL + 'orders/pdf/' + data.id, config,
              //   method: 'GET',
              //   responseType: 'blob',
              // }).then((response) => {
              //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              //   var fURL = document.createElement('a');

              //   fURL.href = fileURL;
              //   fURL.setAttribute('download', 'Invoice ' + data.company.name_company + '.pdf');
              //   document.body.appendChild(fURL);

              //   fURL.click();
              // });

              window.location.href = "https://dummy.oaktree.id/admin/pdf_flexmod/" + data.id
              // axios({
              //       url: 'https://localhost:8000/orders/pdf/' + data.id, // File URL Goes Here
              //       method: 'GET',
              //       responseType: 'blob',
              //   }).then((res) => {
              //        var FILE = window.URL.createObjectURL(new Blob([res.data]));
                     
              //        var docUrl = document.createElement('x');
              //        docUrl.href = FILE;
              //        docUrl.setAttribute('download', 'file.pdf');
              //        document.body.appendChild(docUrl);
              //        docUrl.click();
              //   });
      },
      download_inv_reorder(data){
        window.location.href = "https://dummy.oaktree.id/admin/pdf_reorder/" + data.id
      },
      cancel_order(data){
        console.log(data)
        
        this.cancelOrder(data)
          .then((res) => {
            console.log(res)
            this.getData()
          })
      },
      send_inv(data){
        console.log(data)
        
        this.sendInv(data)
      },

      uploadFile(id) {

        if(this.file == null){

          this.$swal("Warning!", "Cannot upload empty file!", "warning");
        }else{
          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("id", id);
          // console.log(formData)
          this.getPaymentProof(formData);
          // console.log(payment_approve,'payment approve')
          this.$swal({
            title: "Uploaded!",
            text: "Item Uploaded",
            icon: "success",
            // showCancelButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: "#d33",
            confirmButtonText: "oke",
          }).then((result) => {
            if (result.value) {
              // this.deleteOrder(data.id);
              // this.$swal("Deleted!", "Item Deleted", "success");
              window.location.reload()
            }
          });

        }

        // this.order.payment_proof = payment_approve.path;
        // this.order.image_payment_proof = payment_approve.uri;
        // this.url = payment_approve.uri;
        // console.log(payment_approve);
      },

      getData() {
        this.getOrders({
          page: this.page,
          search: this.search
        })
      },
      getSearchData() {
        this.page = 1
        this.getOrders({
          page: this.page,
          search: this.search
        })
      },
      formatDate(dateData) {
        const date = new Date(dateData)
        return date.toDateString()
      },
      hideModal(val) {
        this.dialog = false
        console.log(val, 'data nh')
      }
    },
    mounted() {
      this.getData()
      this.removeErrors();
    },
  };
</script>

<style scoped>
  .thead {
    background-color: #eeeeee;
  }

  .action-width {
    justify-content: left;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .relative {
    position: relative;
  }

  #create .v-speed-dial {
    position: absolute;
  }

  #create .v-btn--floating {
    position: relative;
  }
</style>