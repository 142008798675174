import axios from 'axios'

const END_POINT = "/ref-modules";

export default {
    all(page) {
        return axios.get(`${END_POINT}?page=${page}`);
    },

    search(data) {
        return axios.post(`${END_POINT}/search`, data);
    },
};