<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-3 text-h5 font-weight-light">Form Extra Package</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-extra-package'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- name -->
                <v-col cols="6" class="set-height mt-3">
                  <v-text-field dense outlined label="Nama" :error-messages="errors.name" v-model="extraPackage.name" ></v-text-field>
                </v-col>
                <!-- price -->
                <v-col cols="6" class="set-height mt-3">
                  <vuetify-money
                    v-model="extraPackage.price"
                    label="Price"
                    placeholder="Input price"
                    :readonly="false"
                    :disabled="false"
                    :outlined="false"
                    :clearable="false"
                    :dense="true"
                    :options="options1"
                  />
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save</v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {

  },
  data: () => ({
    options1: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 12,
      precision: 0
    },
    date_schedule: "",
    version : "",
    description : "",
    breads: [
      {
        text: 'Extra Package',
        disabled: false,
        href: 'extra-packages',
      },
      {
        text: 'Create Extra Package',
        disabled: true,
        href: 'extra-packages/create',
      },
    ],
  }),
  props: {
    id: null,
  },
  watch: {
    
  },
  methods: {
    ...mapActions("extraPackage", ["getExtraPackage","storeExtraPackage","updateExtraPackage"]),
    async init(){
      if(typeof this.id !== "undefined"){
        await this.getExtraPackage(this.id)
      } else {
        this.$store.commit('extraPackage/SET_EXTRA_PACKAGE', {});
      }
    },
    store(){
      this.storeExtraPackage(this.extraPackage)
    },
    update(){
      this.updateExtraPackage(this.extraPackage)
    }
  },
  computed: {
    ...mapState("extraPackage", ["extraPackage"]),
    ...mapState(["errors"]),
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.set-height{
  height: 80px;
}

/* .set-height-q{
  height: 400px;
} */
</style>