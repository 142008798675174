export const SET_USERS = (state, payload) => {
    state.users = payload;
};
export const SET_ADMIN = (state, payload) => {
    state.admins = payload;
};


export const SET_USER = (state, payload) => {
    state.user = payload;
};

export const UNSET_USER = (state, payload) => {
    console.log(payload)
    state.users.data = state.users.data.filter((item) => {
        return item.id !== payload;
    });
};