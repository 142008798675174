export const SET_ORDERS = (state, payload) => {
    state.orders = payload;
};

export const SET_ORDER = (state, payload) => {
    state.order = payload;
};
export const UNSET_ORDER = (state, payload) => {
    state.orders.data = state.orders.data.filter((item) => {
        return item.id !== payload;
    });
};