import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueProgressBar from 'vue-progressbar'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import VueSweetalert2 from 'vue-sweetalert2'
import { Datetime } from 'vue-datetime'
import DatetimePicker from 'vuetify-datetime-picker'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
// You need a specific loader for CSS files
import "./plugins/vuetify-money.js";
import 'vue-datetime/dist/vue-datetime.css'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import "./plugins/vuetify-money.js";
import './config/Api'

Vue.filter('numFormat', numFormat(numeral));

Vue.use(VueSweetalert2)
Vue.use(VueBreadcrumbs)
Vue.use(VueAxios, axios)
Vue.use(Datetime)
Vue.use(DatetimePicker)
Vue.use(VueProgressBar, {
    color: "#b2bec3",
    failedColor: "#e84118",
    thickness: "5px",
});
Vue.config.productionTip = false

import Swal from 'sweetalert2';
window.Swal = Swal;

export default new Vue({
    axios,
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')