<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-5 text-h5 font-weight-light">Form Voucher</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-master-voucher'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- name -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Nama" :error-messages="errors.name" v-model="voucher.name_voucher" ></v-text-field>
                </v-col>
                <!-- is all module -->
                <v-col cols="3" class="">
                  <v-checkbox dense label="Is for Order" :error-messages="errors.is_all_module" v-model="voucher.is_all_module" >
                  </v-checkbox>
                </v-col>
                <!-- total user -->
                <!-- <v-col v-show="voucher.is_all_module !== true" cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Jumlah User" :error-messages="errors.total_user" v-model="voucher.total_user" type="number"></v-text-field>
                </v-col> -->
                <v-col v-if="voucher.is_all_module !== true" cols="3">
                  <v-select
                    :items="modules"
                    v-model="voucher.id_module"
                    label="Select Module"
                    item-text="name"
                    item-value="id"
                    @change = "getModule"
                  ></v-select>
                </v-col>

                

                <v-col v-if="voucher.id_module === 1 && voucher.is_all_module !== true && voucher.is_all_user_package !== true" cols="3">
                  <v-autocomplete label="Select User Package" :items="userPackages" item-value="id" :error-messages="errors.id_user_package"
                    v-model="voucher.id_user_package" :search-input.sync="searchUserPackage" >
                  </v-autocomplete>
                </v-col>

                <v-col v-if="voucher.id_module === 2 && voucher.is_all_module !== true && voucher.is_all_product_item !== true" cols="3">
                  <v-autocomplete label="Select Product Item" :items="productItems" item-value="id" :error-messages="errors.id_product_item"
                    v-model="voucher.id_product_item" :search-input.sync="searchProductItem" >
                  </v-autocomplete>
                </v-col>

                <v-col v-if="voucher.id_module === 3 && voucher.is_all_module !== true && voucher.is_all_add_on !== true" cols="3">
                  <v-autocomplete label="Select Add On" :items="addOns" item-value="id" :error-messages="errors.id_add_on"
                    v-model="voucher.id_add_on" :search-input.sync="searchAddOn" >
                  </v-autocomplete>
                </v-col>


                <!-- is all packages -->
                <v-col v-if="voucher.id_module === 1 && voucher.is_all_module !== true" cols="3" class="">
                  <v-checkbox dense label="Is for All Packages" :error-messages="errors.is_all_user_package" v-model="voucher.is_all_user_package">
                  </v-checkbox>
                </v-col>

                <v-col v-if="voucher.id_module === 2 && voucher.is_all_module !== true" cols="3" class="">
                  <v-checkbox dense label="Is for All Product" :error-messages="errors.is_all_product_item" v-model="voucher.is_all_product_item" >
                  </v-checkbox>
                </v-col>

                <v-col v-if="voucher.id_module === 3 && voucher.is_all_module !== true" cols="3" class="">
                  <v-checkbox dense label="Is for All Add On" :error-messages="errors.is_all_add_on" v-model="voucher.is_all_add_on" >
                  </v-checkbox>
                </v-col>

                <v-col  cols="3">
                  <v-select
                    :items="types"
                    v-model="voucher.type_voucher"
                    label="Select Type Voucher"
                    item-text="label"
                    item-value="id"
                  ></v-select>
                </v-col>

                <!-- nominal for Rp -->
                <v-col v-if="voucher.type_voucher === 0" cols="3" class="set-height mt-3">
                  <vuetify-money
                    v-model="voucher.nominal"
                    label="Nominal"
                    placeholder="Input Nominal"
                    :readonly="false"
                    :disabled="false"
                    :outlined="true"
                    :clearable="false"
                    :dense="true"
                    :options="options1"
                  />
                </v-col>

                <!-- nominal for persen -->
                <v-col v-else-if="voucher.type_voucher === 1" cols="12" v-for="(item, k) in nominalPersen" :key="k" class="set-height mt-3">
                  <v-row>
                    <v-col cols="3">
                      <vuetify-money
                        v-model="item.nominal"
                        label="Nominal Persen"
                        placeholder="Input Nominal"
                        :readonly="false"
                        :disabled="false"
                        :outlined="true"
                        :clearable="false"
                        :dense="true"
                        :options="options2"
                      />
                    </v-col>
                    <v-col cols="3">
                      <span class="subtitle-1">
                        <v-btn v-show="k || (!k && nominalPersen.length > 1)" @click="removeNominal(k)" small
                          color="error" dark>
                          Hapus
                        </v-btn>
                        &nbsp;
                        <v-btn v-show="k === nominalPersen.length - 1 && nominalPersen.length < 2" @click="addNominal" small
                          color="info" dark>
                          Tambah
                        </v-btn>
                        
                        &nbsp;
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                

                <!-- start date -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Start Date" :error-messages="errors.start_date" v-model="voucher.start_date"
                    type="date"></v-text-field>
                </v-col>

                <!-- end date -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="End Date" :error-messages="errors.end_date" v-model="voucher.end_date"
                    type="date"></v-text-field>
                </v-col>
                
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save</v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
// import { Datetime } from 'vue-datetime';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    // datetime: Datetime
  },
  data: () => ({
    options1: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 12,
      precision: 0
    },
    options2: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 3,
      precision: 0
    },
    modules: [{
      'id' : 1,
      'name' : 'User Packages'
      },
      {
      'id' : 2,
      'name' : 'Product Items'
      },
      {
      'id' : 3,
      'name' : 'Add On'
      }
    ],
    types: [
      {
        'id' : 0,
        'label' : 'Rp'
      },
      {
      
        'id' : 1,
        'label' : '%'
      }
    ],
    nominalPersen:[{
      nominal:0
    }],
    date_schedule: "",
    searchUserPackage:"",
    searchProductItem:"",
    searchAddOn:"",
    version : "",
    description : "",
    breads: [
      {
        text: 'Master Voucher',
        disabled: false,
        href: 'master-voucher',
      },
      {
        text: 'Create Master Voucher',
        disabled: true,
        href: 'master-voucher/create',
      },
    ],
  }),
  props: {
    id: null,
  },
  watch: {
    searchUserPackage: function (searchUserPackage) {
        let params = {
          name: searchUserPackage,
        };
        this.$store.dispatch("userPackage/searchUserPackages", params);
    },
    searchProductItem: function (searchProductItem) {
        let params = {
          name: searchProductItem,
        };
        this.$store.dispatch("productItem/searchProductItems", params);
    },
    searchAddOn: function (searchAddOn) {
        let params = {
          name: searchAddOn,
        };
        this.$store.dispatch("addOn/searchAddOns", params);
    },
  },
  methods: {
    ...mapActions("userPackage", ["getUserPackage","storeUserPackage","updateUserPackage","getUserPackages"]),
    ...mapActions("productItem", ["getProductItems"]),
    ...mapActions("masterVoucher", ["getVoucher","storeVoucher","updateVoucher"]),
    ...mapActions("addOn", ["getAddOns"]),
    addNominal() {
        if (!this.nominalPersen) {
          return
        }
        this.nominalPersen.push({
          // value: ''
          nominal: 0
        });



    },
    removeNominal(x){
        // console.log(x, 'index removeremarks')
        this.nominalPersen.splice(x, 1)
        this.saveNominal()
    },

    saveNominal() {
        let parsed = JSON.stringify(this.nominalPersen);

        localStorage.setItem('Nominal', parsed)
    },
    
    getModule(){
      var idModule = this.userPackage.id_module
      // get module
      if(idModule === 1){
        // get list user packages
        this.getUserPackages()
      }else if(idModule === 2) {
        // get list product item 
        this.getProductItems()
      }else if(idModule === 3) {
        this.getAddOns()
      }
    },
    async init(){
      if(typeof this.id !== "undefined"){
        let dataVoucher = await this.getVoucher(this.id)
        if(dataVoucher.id_product_item == "0" && dataVoucher.id_user_package == null && dataVoucher.id_add_on == null){
          this.voucher.is_all_product_item = true
          this.voucher.is_all_user_package = false
          this.voucher.is_all_add_on = false
        }else if(dataVoucher.id_product_item == null && dataVoucher.id_add_on == null && dataVoucher.id_user_package == "0"){
          this.voucher.is_all_product_item = false
          this.voucher.is_all_add_on = false
          this.voucher.is_all_user_package = true
        }else if(dataVoucher.id_product_item == null && dataVoucher.id_user_package == null && dataVoucher.id_add_on == "0"){
          this.voucher.is_all_product_item = false
          this.voucher.is_all_user_package = false
          this.voucher.is_all_add_on = true
        }

        if(dataVoucher.is_all_module == 1){
          this.voucher.is_all_module = true
        }else{
          this.voucher.is_all_module = false
        }
        if(dataVoucher.type_voucher == 1){
          let arrayNominal = this.voucher.nominal.split('|')
          console.log(arrayNominal)
          this.nominalPersen = []
          for (let i = 0; i < arrayNominal.length; i++) {
            const element = arrayNominal[i];
            this.nominalPersen.push({
              nominal:element
            })
          }
        }
        this.voucher.id_product_item = parseInt(dataVoucher.id_product_item)
        this.voucher.id_user_package = parseInt(dataVoucher.id_user_package)
        this.voucher.id_add_on = parseInt(dataVoucher.id_add_on)
        console.log(this.voucher.is_all_module)
        console.log(dataVoucher)
      } else {
        this.$store.commit('masterVoucher/SET_VOUCHER', {});
      }
    },
    store(){
      console.log(this.nominalPersen, 'isi nominal persen')
      if(this.voucher.type_voucher == 1){
        let arrayNominal = []
        for (let i = 0; i < this.nominalPersen.length; i++) {
          const element = this.nominalPersen[i];
          arrayNominal.push(element.nominal)
        }
        this.voucher.nominal = arrayNominal
      }
      this.storeVoucher(this.voucher)
      console.log(this.voucher)
    },
    update(){
      if(this.voucher.type_voucher == 1){
        let arrayNominal = []
        for (let i = 0; i < this.nominalPersen.length; i++) {
          const element = this.nominalPersen[i];
          arrayNominal.push(element.nominal)
        }
        this.voucher.nominal = arrayNominal
      }
      this.updateVoucher(this.voucher)
    }
  },
  computed: {
    ...mapState("masterVoucher", ["voucher", "vouchers"]),
    ...mapState("userPackage", ["userPackage", "userPackages"]),
    ...mapState("productItem", ["productItems"]),
    ...mapState("addOn", ["addOns"]),
    ...mapState(["errors"]),
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.set-height{
  height: 80px;
}

/* .set-height-q{
  height: 400px;
} */
</style>