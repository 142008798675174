import ExtraPackage from "../../../api/ExtraPackage";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getExtraPackages = ({ commit }, payload) => {
    ExtraPackage.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_EXTRA_PACKAGES", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchExtraPackages = ({ commit }, payload) => {
    ExtraPackage.search(payload)
        .then((response) => {
            commit("SET_EXTRA_PACKAGES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getExtraPackage = ({ commit }, payload) => {
    return ExtraPackage.show(payload)
        .then((response) => {
            commit("SET_EXTRA_PACKAGE", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeExtraPackage = ({ dispatch }, payload) => {
    ExtraPackage.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-extra-package" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateExtraPackage = ({ dispatch }, payload) => {
    ExtraPackage.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-extra-package" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteExtraPackage = ({ commit }, payload) => {
    ExtraPackage.delete(payload)
        .then(() => {
            commit("UNSET_EXTRA_PACKAGE", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};