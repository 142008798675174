<template>
  <!-- App wrapper -->
	<v-app>
		<!-- Navigation -->
		<v-app-bar color="primary" dark app>
			<v-toolbar-title>Login</v-toolbar-title>
		</v-app-bar>

		<!-- Content Wrapper -->
		<v-main>
			<!-- Provides the application the proper gutter -->
			<v-container fluid class="fill-height">
				<!-- Content -->
				<router-view></router-view>
			</v-container>
		</v-main>

    <!-- footer -->
    <web-footer></web-footer>

	</v-app>
</template>

<script>

import WebFooter from "@/components/admin/WebFooter";

export default {
  components: {
    WebFooter
  }
}
</script>

<style>

</style>