import axios from 'axios'

const END_POINT = "/user-storages";

export default {
    all(page, search) {
        return axios.get(`${END_POINT}?page=${page}&search=${search}`);
    },
    search(data) {
        return axios.post(`${END_POINT}/search`, data);
    },
    show(id) {
        return axios.get(`${END_POINT}/${id}`);
    },

    store(data) {
        return axios.post(`${END_POINT}/store`, data);
    },

    update(data) {
        return axios.put(`${END_POINT}/update/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${END_POINT}/delete/${id}`);
    },
};