<template>
  <div>
    <v-footer
      padless
    >
      <v-card
        flat
        tile
        width="100%"
      >
        <v-card-text class=" text-right">
          <div class="body-1 font-weight-light pt-6 pt-md-0">
            &copy; {{ new Date().getFullYear() }}
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: [
      'mdi-home',
      'mdi-email',
      'mdi-calendar',
      'mdi-delete',
    ],
    items: [
      'default',
      'absolute',
      'fixed',
    ],
    padless: false,
    variant: 'default',
  }),
  computed: {
    localAttrs () {
      const attrs = {}

      if (this.variant === 'default') {
        attrs.absolute = false
        attrs.fixed = false
      } else {
        attrs[this.variant] = true
      }
      return attrs
    },
  },
}
</script>

<style>

</style>