import axios from 'axios'

const END_POINT = "/users";

export default {


    all(page, search) {
        return axios.get(`${END_POINT}?page=${page}&search=${search}`);
    },

    search(data) {
        return axios.post(`${END_POINT}/search`, data);
    },

    show(id) {
        return axios.get(`${END_POINT}/${id}`);
    },

    store(data) {
        return axios.post(`${END_POINT}/store`, data);
    },

    update(data) {
        return axios.put(`${END_POINT}/update/${data.id_company}`, data);
    },

    delete(id) {
        return axios.delete(`${END_POINT}/delete/${id}`);
    },
    get_admin() {
        return axios.get(`${END_POINT}/admin`);
    },
    search_admin(data) {
        return axios.post(`${END_POINT}/search_admin`, data);
    },
    update_admin(data) {
        return axios.post(`${END_POINT}/update_admin`, data);
    },

    show_admin() {
        return axios.get(`${END_POINT}/show_admin`);
    },
};