import axios from 'axios'

const END_POINT = "/sub-modules";

export default {
    
    show(id) {
        return axios.get(`${END_POINT}/${id}`);
    },
    search(data) {
        return axios.post(`${END_POINT}/search`, data);
    },

};