<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-5" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Data User Package</h2>
            <!-- Add Button -->
          </v-card-title>
          <v-card-title>
            <!-- Search data -->
            <v-text-field
              append-icon="mdi-magnify"
              :items="userPackages"
              item-value="id"
              :search-input.sync="searchUserPackage"
              label="Search"
              v-model="search"
              single-line
              hide-details
              class="font-weight-light"
            ></v-text-field>
            <v-btn
                rounded
                elevation="2"
                color="success"
                dark
                small
                class="text-capitalize font-weight-light white--text ml-5"
                @click="getSearchData">
              Search
            </v-btn>
            <!-- Add button -->
            <v-btn
              rounded
              elevation="2"
              color="info"
              dark
              small
              class="text-capitalize font-weight-light white--text ml-5"
              :to="{name: 'admin-user-package-create'}">
              Add
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Table -->
        <div>

          <v-simple-table>
            <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Harga</th>
              <th>Jumlah User</th>
              <th>Aksi</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in userPackages" :key="data.id">
                <td>{{ ((page-1)*10)+index+1 }}</td>
                <td>{{ data.name }}</td>
                <td>Rp.{{ data.price | numFormat }}</td>
                <td>{{ data.total_user }}</td>
                <td>
                  <v-col>
                    <v-row>
                        <v-btn
                          dark
                          elevation="0"
                          small
                          color="yellow darken-2"
                          class="text-capitalize font-weight-light"
                          :to="{name: 'admin-user-package-edit', params: { id: data.id }}"
                        >
                          edit
                        </v-btn>

                        <v-btn
                          dark
                          elevation="0"
                          small
                          color="error"
                          class="ml-3 text-capitalize font-weight-light"
                          @click="confirm(data)"
                        >
                          Delete
                        </v-btn>
                    </v-row>
                  </v-col>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <!-- paginate -->
          <div class="text-right pb-10 mt-5">
            <v-pagination
                v-model="page"
                :length="totalPage"
                @change="getData()"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data () {
    return {
      page : 1 ,
      dialog: false,
      breads: [
        {
          text: 'User Package',
          disabled: false,
          href: 'user-packages',
        },
        {
          text: 'Create user package',
          disabled: false,
          href: 'user-packages/create',
        },
      ],
      search: '',
      searchUserPackage: '',
    }
  },
  computed: {
    ...mapState("userPackage", ["userPackages"]),
    totalPage: function () {
      if(typeof this.userPackages.meta !== "undefined")
        return this.userPackages.meta.last_page
      return 1
    }
  },
  watch:{
    page : function (){
      this.getData();
    },
  },
  methods: {
    ...mapActions(["removeErrors"]),
    ...mapActions("userPackage", ["getUserPackages", "changeStatus", "deleteUserPackage"]),
    confirm(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteUserPackage(data.id);
          this.$swal("Deleted!", "Item Deleted", "success");
        }
      });
    },
    getData(){
      this.getUserPackages({page: this.page, search : this.search})
    },
    getSearchData(){
      this.page = 1
      this.getUserPackages({page: this.page, search : this.search})

    }
  },
  mounted() {
    this.getData();
    this.removeErrors();
    console.log(this.userPackages,'data')
  },
};
</script>

<style scoped>
.thead{
  background-color: #eeeeee;
}

.action-width{
  justify-content: left;
}
.search{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>