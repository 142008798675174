export const SET_PRODUCT_ITEMS = (state, payload) => {
    state.productItems = payload;
};

export const SET_PRODUCT_ITEM = (state, payload) => {
    state.productItem = payload;
};

export const UNSET_PRODUCT_ITEM = (state, payload) => {
    state.productItems.data = state.productItems.data.filter((item) => {
        return item.id !== payload;
    });
};