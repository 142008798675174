<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-5 text-h5 font-weight-light">Form Master Packages</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-master-packages'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- name -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Nama" :error-messages="errors.nama_paket" v-model="master_package.nama_paket" ></v-text-field>
                </v-col>
                <v-col v-if="isManual === false" cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Kode Paket" readonly :error-messages="errors.kode_paket" v-model="master_package.kode_paket" :append-icon="isManual ? 'mdi-pencil' : 'mdi-pencil-off'" @click:append="isManual = !isManual" ></v-text-field>
                </v-col>
                <v-col v-if="isManual === true" cols="3" >
                  <v-text-field
                  
                    v-model="kode_paket_manual"
                    :append-icon="isManual ? 'mdi-pencil' : 'mdi-pencil-off'"
                    :type="isManual ? 'text' : 'text'"
                    :readonly="isManual ? false: true"
                    name="input-10-1"
                    label="Kode Paket"
                    hint="Input manual kode"
                    @click:append="isManual = !isManual"
                  ></v-text-field>
                </v-col>

                <v-col  cols="3">
                  <v-autocomplete label="Select Product Item" :items="productItems" item-value="id" :error-messages="errors.product_item_id"
                    v-model="master_package.product_item_id" :search-input.sync="searchProductItem" >
                  </v-autocomplete>
                </v-col>
                
                <v-col  cols="3">
                  <v-autocomplete label="Select User Package" :items="userPackages" item-value="id" :error-messages="errors.user_package_id"
                    v-model="master_package.user_package_id" :search-input.sync="searchUserPackage" >
                  </v-autocomplete>
                </v-col>

                <v-col  cols="3">
                  <v-autocomplete label="Select User Storage" :items="userStorages" item-value="id" :error-messages="errors.user_storage_id"
                    v-model="master_package.user_storage_id" :search-input.sync="searchUserStorage" >
                  </v-autocomplete>
                </v-col>

                <v-col  cols="3">
                  <v-autocomplete label="Select Add On (Optional)" :items="addOns" item-value="id" :error-messages="errors.add_on_id"
                    v-model="master_package.add_on_id" :search-input.sync="searchAddOn" multiple>
                  </v-autocomplete>
                </v-col>

                <!-- start date -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Start Date" :error-messages="errors.start_date" v-model="master_package.start_date"
                    type="date"></v-text-field>
                </v-col>

                <!-- end date -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="End Date" :error-messages="errors.end_date" v-model="master_package.end_date"
                    type="date"></v-text-field>
                </v-col>
                
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save</v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
// import { Datetime } from 'vue-datetime';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    // datetime: Datetime
  },
  data: () => ({
    
    date_schedule: "",
    searchUserPackage:"",
    searchProductItem:"",
    searchUserStorage:"",
    searchAddOn:"",
    version : "",
    description : "",
    isManual: false,
    kode_paket_manual: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    },
    breads: [
      {
        text: 'Master Package',
        disabled: false,
        href: 'master-packages',
      },
      {
        text: 'Create Master Package',
        disabled: true,
        href: 'master-packages/create',
      },
    ],
  }),
  props: {
    id: null,
  },
  watch: {
    searchUserPackage: function (searchUserPackage) {
        let params = {
          name: searchUserPackage,
        };
        this.$store.dispatch("userPackage/searchUserPackages", params);
    },
    searchProductItem: function (searchProductItem) {
        let params = {
          name: searchProductItem,
        };
        this.$store.dispatch("productItem/searchProductItems", params);
    },
    searchAddOn: function (searchAddOn) {
        let params = {
          name: searchAddOn,
        };
        this.$store.dispatch("addOn/searchAddOns", params);
    },
    searchUserStorage: function (newSearchUserStorage) {
        let params = {
          name: newSearchUserStorage,
        };
        this.$store.dispatch("userStorage/searchUserStorages", params);
    },
  },
  methods: {
    ...mapActions("userPackage", ["getUserPackage","storeUserPackage","updateUserPackage","getUserPackages"]),
    ...mapActions("productItem", ["getProductItems"]),
    ...mapActions("userStorage", ["getUserStorages", 'getUserStorage']),
    ...mapActions("masterPackage", ["getPackage","storePackage","updatePackage", "getKodePaket"]),
    ...mapActions("addOn", ["getAddOns"]),

    

    async init(){
      if(typeof this.id !== "undefined"){
        let dataPaket = await this.getPackage(this.id)

        let arrayAddOn = []
        for (let i = 0; i < dataPaket.add_on_id.length; i++) {
          const element = dataPaket.add_on_id[i];
          arrayAddOn.push(parseInt(element))
        }
        this.master_package.add_on_id = arrayAddOn
        
      } else {
        this.$store.commit('masterPackage/SET_PACKAGE', {});
        let kode_paket = await this.getKodePaket();
        this.master_package.kode_paket = kode_paket;
      }
    },
    store(){
      // this.master_package.is_manual = this.isManual
      if(this.isManual == true){
        this.master_package.kode_paket  = this.kode_paket_manual
      }
      this.storePackage(this.master_package)
    },
    update(){
      if(this.isManual == true){
        this.master_package.kode_paket  = this.kode_paket_manual
      }
      this.updatePackage(this.master_package)
    }
  },
  computed: {
    ...mapState("masterPackage", ["master_package", "master_packages"]),
    ...mapState("userPackage", ["userPackage", "userPackages"]),
    ...mapState("productItem", ["productItems"]),
    ...mapState("addOn", ["addOns"]),
    ...mapState("userStorage", ["userStorages", 'userStorage']),
    ...mapState(["errors"]),
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.set-height{
  height: 80px;
}

/* .set-height-q{
  height: 400px;
} */
</style>