<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-2 ma-4 mt-5">
        <v-row class="pa-4" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="font-weight-light">Form Product Item</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small
              :to="{name: 'admin-product-item'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div class="pa-2">
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- name -->
                <v-col cols="4" class="">
                  <v-text-field dense label="Name" :error-messages="errors.name" v-model="productItem.name">
                  </v-text-field>
                </v-col>


                <!-- is aol -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Is AOL" :error-messages="errors.is_aol" v-model="productItem.is_aol">
                  </v-checkbox>
                </v-col>

                <!-- is internal/eksternal -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Is Eksternal" :error-messages="errors.is_showing" v-model="productItem.is_showing">
                  </v-checkbox>
                </v-col>

                <!-- is billing -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Billing" :error-messages="errors.is_billing" v-model="productItem.is_billing">
                  </v-checkbox>
                </v-col>

                <!-- is Free -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Is Free" :error-messages="errors.is_free" v-model="productItem.is_free">
                  </v-checkbox>
                </v-col>

                <!-- description -->
                <v-col class="" cols="12">
                  <p>Description :</p>
                  <quill-editor v-model="productItem.description" :error-messages="errors.description" />
                </v-col>

                <v-col cols="12">
                  <v-stepper v-model="e1">
                    <v-stepper-header>
                      <v-stepper-step :complete="e1 > 1" step="1">
                        General
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step :complete="e1 > 2" step="2">
                        Add On
                      </v-stepper-step>

                      <v-divider></v-divider>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <!-- <v-card class="mb-12" color="white lighten-1" height="200px"> -->
                        <!-- sub modules -->
                        <v-col cols="12" class="">

                          <v-autocomplete filled dense :items="dataOrder" item-value="id" item-text="name"
                            v-model="module" multiple :search-input.sync="searchDataOrder" @change="getSubmodule()">
                          </v-autocomplete>

                        </v-col>
                        <v-col cols="12" v-for="(element, k) in dataSubmodule" :key="k">
                          <h4 v-if="element.module == 1">
                            Marketing
                          </h4>
                          <h4 v-else-if="element.module == 2">
                            Operation
                          </h4>
                          <h4 v-else-if="element.module == 3">
                            Master
                          </h4>
                          <h4 v-else-if="element.module == 4">
                            Report
                          </h4>
                          <h4 v-else-if="element.module == 5">
                            Setting
                          </h4>
                          <h4 v-else-if="element.module == 6">
                            Internal
                          </h4>
                          <!-- <v-select v-model="submodule" :items="element.data" label="Choose Sub Module" multiple item-text="name" item-value="id"
                    chips persistent-hint @change="getItem()"></v-select> -->
                          <v-card v-if="status == true" max-height="400px" class="overflow-y-auto"
                            v-scroll.self="onScroll">
                            <v-row>
                              <v-col cols="12">

                                <v-card-text>
                                  <v-row v-for="(item, j) in element.data" :key="j">
                                    <v-col cols="3" style="padding-bottom:0px !important">
                                      <v-checkbox v-model="item.submodule" :value="item.id" :label="item.name">
                                      </v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                      <vuetify-money v-model="item.price" label="Price (Rp)" placeholder="Input price"
                                        :readonly="false" :disabled="false" :outlined="false" :clearable="false"
                                        :dense="true" :options="options1" />

                                      <!-- <v-text-field type="number" dense label="Price" :error-messages="errors.total_user" v-model="item.price" @input="totalPrice(j)"></v-text-field> -->
                                    </v-col>
                                    <!-- <v-col cols="2">
                                      <vuetify-money v-model="item.disc" label="Disc (Rp)" placeholder="Input price"
                                        :readonly="false" :disabled="false" :outlined="false" :clearable="false"
                                        :dense="true" :options="options1" />
                                    </v-col> -->
                                    <v-col cols="2">
                                      <label for="">Total</label>
                                      <!-- <vuetify-money v-model="item.total" label="Price" placeholder="Input price" :readonly="false"
                                :disabled="false" :outlined="false" :clearable="false" :dense="true" :options="options1" /> -->

                                    </v-col>
                                    <v-col cols="2" v-if="item.disc == null && item.price == null">
                                      Rp 0
                                    </v-col>
                                    <v-col cols="2" v-else-if="item.disc == null && item.price != null">
                                      Rp {{item.price }}
                                    </v-col>
                                    <v-col cols="2" v-else-if="item.disc != null && item.price == null">
                                      Rp {{item.price }}
                                    </v-col>
                                    <v-col cols="2" v-else-if="item.disc != null && item.price != null">
                                      Rp {{item.price - item.disc}}
                                    </v-col>

                                  </v-row>
                                </v-card-text>

                              </v-col>
                            </v-row>
                          </v-card>


                        </v-col>
                        <!-- </v-card> -->
                        <div class="text-right">
                          <v-btn color="cyan" @click="e1 = 2" class="text-left white--text font-weight-light ma-4 text-capitalize">
                            Next
                          </v-btn>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-autocomplete filled dense :items="addOns" item-value="id" item-text="text"
                            v-model="arrayAddOn" multiple :search-input.sync="searchAddOn" @change="getAddOns()">
                          </v-autocomplete>
                        <div class="text-right">
                          <v-btn color="blue-grey lighten-2" @click="e1 = 1" class="text-left white--text font-weight-light ma-4 text-capitalize">
                            Previous
                          </v-btn>
                          <v-btn color="cyan" type="submit" class="text-right white--text font-weight-light ma-4 text-capitalize">
                            Save
                          </v-btn>
                        </div>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>



              </v-row>

            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
  import {
    mapActions,
    mapState
  } from "vuex";
  import axios from 'axios';

  import {
    quillEditor
  } from "vue-quill-editor";
  export default {
    components: {
      quillEditor,
      // Multiselect
    },
    data: () => ({
      e1: 1,
      options1: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 9,
        precision: 0
      },
      items: [],
      searchRefModule: "",
      searchDataOrder: "",
      searchAddOn: "",
      ref_module_id: "",
      name: "",
      description: "",
      refModule: "",
      status: false,
      module: [],
      arrayAddOn:[],
      arrayAddOns:[{
        module: '',
        data: [{
          submodule: '',
          price: 0,
          disc: 0,
          total: 0
        }]
      }],
      test:[{
        module: '',
        data: [{
          submodule: '',
          price: 0,
          disc: 0,
          total: 0
        }]
      }],
      newModule: [],
      dataOrder: [],
      subModuleArray: [],
      dataSubmodule: [{
        module: '',
        data: [{
          submodule: '',
          price: 0,
          disc: 0,
          total: 0
        }]
      }],
      rules: {
        required: value => !!value || 'Required.'
      },
      submodule: [],
      chart: [],
      breads: [{
          text: 'Product Item',
          disabled: false,
          href: 'product-item',
        },
        {
          text: 'Create product item',
          disabled: true,
          href: 'product-item/create',
        },
      ],
    }),
    props: {
      id: null,
    },
    watch: {
      searchDataOrder: function (newSearchDataOrder) {
        let params = {
          name: newSearchDataOrder,
        };
        this.$store.dispatch("refModule/searchRefModules", params);

      },
      searchAddOn: function (newSearchAddOn) {
        let params = {
          name: newSearchAddOn,
        };
        this.$store.dispatch("addOn/searchAddOns", params);

      },
    },
    methods: {
      ...mapActions("productItem", ["getProductItem", "storeProductItem", "updateProductItem"]),
      ...mapActions("refModule", ["getRefModules"]),
      ...mapActions("addOn", ["getAddOns","getAddOn"]),
      ...mapActions("subModule", ["getSubModule"]),
      totalPrice(j) {
        console.log(j, 'index')
      },
       groupByKey(array, key) {
        return array
          .reduce((hash, obj) => {
            if(obj[key] === undefined) return hash; 
            return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
          }, {})
      },
      async getAddOns(){
        console.log(this.arrayAddOn, 'isi add on')
        let thisArray = []
        this.arrayAddOns = []
        this.arrayAddOn.forEach(element => {
            axios.get('/add-ons/'+ element).then((response) => {
              let res = response.data.data;
              
              console.log(res.product_item_details,'length')
              res.product_item_details.forEach(el => {
                // console.log(el.ref_submodule_id, 'isi el')
                axios.get('/sub-modules/get/' + el.ref_submodule_id).then((response) => {
                  console.log(response.data.data, 'rest sub module')
                 thisArray.push({
                    module: el.ref_module_id,
                    data: response.data.data
                  })
                  //  = this.arrayAddOns
                  // thisArray = this.arrayAddOns
                  // var result = this.groupByKey(thisArray, 'module');
                  // console.log(result,'hasil result')
                  // let group = thisArray.reduce((r, a) => {
                  //   console.log("a", a);
                  //   console.log('r', r);
                  //   r[a.module] = [...r[a.module] || [], a];
                  //   return r;
                  //   }, {});
                  //   console.log("group", group);
                    // this.arrayAddOns = group
                  // thisArray.push(this.arrayAddOns)
                  console.log(this.arrayAddOns, 'yuhuu')

                  this.arrayAddOns = [...new Map(thisArray.map(item => [item.module, item])).values()]
                }, () => {
                  console.log("gagal")
                });

                console.log(this.arrayAddOns, 'isi this.arrayAddOns')

              })

              // for (let i = 0; i < res.product_item_details.length; i++) {
              //   const element = res.product_item_details[i];
              //   console.log(element.ref_submodule_id,'isi dataSubmodule')
              //   axios.get('/sub-modules/get/' + element.ref_submodule_id).then((response) => {
              //     console.log(response.data.data, 'rest sub module')
              //     this.arrayAddOns.push({
              //       module: element.ref_module_id,
              //       data: response.data.data
              //     })
              //     //  = this.arrayAddOns
              //     console.log(this.arrayAddOns, 'isi this.arrayAddOns')
              //     // this.arrayAddOns = [...new Map(this.arrayAddOns.map(item => [item.module, item])).values()]
              //     console.log(this.arrayAddOns, 'yuhuu')
              //   }, () => {
              //     console.log("gagal")
              //   });
              // }

            }, () => {
              console.log("cant get ref module")
            });

        });

                  // this.arrayAddOns  = [...new Map(thisArray.map(item => [item.module, item])).values()]

        // this.arrayAddOns =[]
      },
      getSubmodule() {
        let moduleArray = []
        moduleArray = this.module
        this.dataSubmodule = []
        this.status = true
        this.submodule = []


        const seen = new Set();
        this.newModule = moduleArray.filter(el => {
          const duplicate = seen.has(el);
          seen.add(el);
          return !duplicate;
        });
        let array = this.newModule
        if (this.newModule != []) {
          array.forEach(element => {
            console.log(element, 'isi element')
            axios.get('/sub-modules/' + element).then((response) => {
              console.log(response.data.data, 'rest sub module')
              this.dataSubmodule.push({
                module: element,
                data: response.data.data
              })
              // this.subModuleArray = array2
              this.dataSubmodule = [...new Map(this.dataSubmodule.map(item => [item.module, item])).values()]

            }, () => {
              console.log("gagal")
            });
          });
          // this.dataSubmodule = []
          
        }
      },
      addSubModule(id) {
        this.submodule.push({
          sub_module: id
        })

        console.log(this.submodule)
      },
      // getText(item){
      //   return '${item.name}'
      // },
      async init() {
        // this.getRefModules()
        axios.get('/ref-modules').then((response) => {
          this.dataOrder = response.data.data;
          

        }, () => {
          console.log("cant get ref module")
        });
        if (typeof this.id !== "undefined") {
          let res = await this.getProductItem(this.id);
          // this.dataOrder = 
          // this.module = 
          let arrayModule = []
          this.dataSubmodule = []
          for (let i = 0; i < res.product_item_details.length; i++) {
            arrayModule.push(res.product_item_details[i].ref_module_id)
          }
          const seen = new Set();
          this.module = arrayModule.filter(el => {
            const duplicate = seen.has(el);
            seen.add(el);
            return !duplicate;
          });

          var result = Object.entries(res.dataSubModule).map(([key, value]) => ({
            key,
            value
          }));
          // console.log(result, 'data submodule')
          for (let k = 0; k < result.length; k++) {
            const element = result[k];
            this.dataSubmodule.push({
              'module': element.key,
              'data': element.value
            })
            // this.module.push(element.key)
          }
          this.status = true
          let arrayAddOn = []
          // console.log(res.addOns.add_on,'isi add on')
          for (let x = 0; x < res.addOns.length; x++) {
            const element = res.addOns[x];
            arrayAddOn.push(element.add_on_id)
          }

          const seenAddOn = new Set();
          this.arrayAddOn = arrayAddOn.filter(el => {
            const duplicate = seenAddOn.has(el);
            seenAddOn.add(el);
            return !duplicate;
          });
          console.log(this.arrayAddOn,'isi array add on')
          // console.log(result);
          console.log(this.dataSubmodule, 'array submodule')
          // console.log(this.module)
          // console.log(res,'respon')



        } else {
          this.$store.commit('productItem/SET_PRODUCT_ITEM', {});
        }
      },
      store() {
        if (this.dataSubmodule.length < 1) {
          this.$swal({
            title: "Ref Submodule",
            text: "Ref Submodule tidak boleh kosong!",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          });
        } else {
          // this.productItem.module = this.module
          // this.storeProductItem(this.productItem)
          console.log(this.dataSubmodule)
          console.log(this.arrayAddOn, 'isi add on')
          let arraySubmodule = []
          let total = 0
          let totalDisc = 0
          for (let i = 0; i < this.dataSubmodule.length; i++) {
            const element = this.dataSubmodule[i];
            console.log(element.data)
            for (let j = 0; j < element.data.length; j++) {
              const data = element.data[j];
              if (data.submodule) {
                console.log(data.submodule, 'ada submodule')
                let getTotal = 0
                console.log(data.disc, 'disc')
                if (typeof data.disc != 'undefined') {
                  getTotal = data.price - data.disc
                  total += getTotal
                  totalDisc = parseInt(totalDisc) + parseInt(data.disc)
                  arraySubmodule.push(data)
                } else {
                  getTotal = data.price - 0
                  total += getTotal
                  totalDisc = parseInt(totalDisc) + parseInt(0)
                  arraySubmodule.push(data)
                }

              }
            }
          }
          console.log(totalDisc)

          this.productItem.total = total
          this.productItem.totalDisc = totalDisc
          this.productItem.submodule = arraySubmodule
          this.productItem.addOns = this.arrayAddOn
          // console.log(arraySubmodule)
          this.storeProductItem(this.productItem)
        }


      },
      update() {
        if (this.module.length < 1) {
          this.$swal({
            title: "Ref Module",
            text: "Ref Module tidak boleh kosong!",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          });
        } else {
          let arraySubmodule = []
          let total = 0
          let totalDisc = 0
          for (let i = 0; i < this.dataSubmodule.length; i++) {
            const element = this.dataSubmodule[i];
            console.log(element.data)
            for (let j = 0; j < element.data.length; j++) {
              const data = element.data[j];
              if (data.submodule) {
                console.log(data, 'ada submodule')
                let getTotal = data.price - data.disc
                total += getTotal
                totalDisc = parseInt(totalDisc) + parseInt(data.disc)
                arraySubmodule.push(data)
              }
            }
          }


          this.productItem.total = total
          this.productItem.totalDisc = totalDisc
          this.productItem.submodule = arraySubmodule
          this.productItem.addOns = this.arrayAddOn

          this.updateProductItem(this.productItem)
        }
      }
    },
    computed: {
      ...mapState("productItem", ["productItem"]),
      ...mapState("refModule", ["refModules"]),
      ...mapState("addOn", ["addOns"]),
      ...mapState("subModule", ["subModule"]),
      ...mapState(["errors"]),


    },

    mounted() {
      this.init();

    },
  };
</script>

<style scoped>
  .set-height {
    height: 80px;
  }

  .ww {
    max-width: 50px;
  }

  /* .set-height-q{
  height: 400px;
} */
</style>