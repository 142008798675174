import User from "../../../api/User";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getUsers = ({ commit }, payload) => {
    User.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_USERS", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchUsers = ({ commit }, payload) => {
    User.search(payload)
        .then((response) => {
            commit("SET_USERS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getUser = async({ commit }, payload) => {
    return await User.show(payload)
        .then((response) => {
            response.data.data.id_company = response.data.data.company ? response.data.data.company.id_company : ""
            response.data.data.default_branch = response.data.data.branch ? response.data.data.branch.id : ""
            response.data.data.name_user = response.data.data.profile.name_user
            response.data.data.mobile_user = response.data.data.profile.mobile_user
            response.data.data.id_group = response.data.data.group ? response.data.data.group.id : 0
            response.data.data.id_role = response.data.data.role ? response.data.data.role.id_role : 0
            commit("SET_USER", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeUser = ({ dispatch }, payload) => {
    User.store(payload)
        .then(() => {
            // dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-user" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateUser = ({ dispatch }, payload) => {
    console.log(payload)
    User.update(payload)
        .then(() => {
            // dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-user" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteUser = ({ commit }, payload) => {
    User.delete(payload)
        .then(() => {
            commit("UNSET_USER", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getAdmin = async({ commit }, payload) => {
    return await User.get_admin(payload)
        .then((response) => {
            console.log(response, 'get admin')
            commit("SET_ADMIN", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchAdmin = async({ commit }, payload) => {
    return await User.search_admin(payload)
        .then((response) => {
            console.log(response.data.data)
            commit("SET_ADMIN", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};

export const updateAdmin = ({ dispatch }, payload) => {
    User.update_admin(payload)
        .then(() => {
            // dispatch("removeErrors", null, { root: true });
            router.push({ name: "setting-background" });
            Swal.fire('Success', 'Email has been save!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const showAdmin = async({ commit }, payload) => {
    return await User.show_admin(payload)
        .then((response) => {
            console.log(response, 'init admin')
            commit("SET_ADMIN", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};