export const SET_COMPANIES = (state, payload) => {
    state.companies = payload;
};

export const SET_COMPANY = (state, payload) => {
    state.company = payload;
};

export const UNSET_COMPANY = (state, payload) => {
    state.companies.data = state.companies.data.filter((item) => {
        return item.id_company !== payload;
    });
};