<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-2 ma-4 mt-5">
        <v-row class="pa-4" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="font-weight-light">Form Add On</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small
              :to="{name: 'admin-add-on'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div class="pa-2">
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- name -->
                <v-col cols="6" class="">
                  <v-text-field dense label="Name" :error-messages="errors.name" v-model="addOn.name">
                  </v-text-field>
                </v-col>
<!-- is internal/eksternal -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Is Eksternal" :error-messages="errors.is_showing" v-model="addOn.is_showing">
                  </v-checkbox>
                </v-col>
                <!-- is billing -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Billing" :error-messages="errors.is_billing" v-model="addOn.is_billing">
                  </v-checkbox>
                </v-col>
                <!-- is Free -->
                <v-col cols="2" class="">
                  <v-checkbox dense label="Is Free" :error-messages="errors.is_free" v-model="addOn.is_free">
                  </v-checkbox>
                </v-col>
                <!-- price -->
                <!-- <v-col cols="3" class="">
                  <vuetify-money v-model="addOn.price" label="Price" placeholder="Input price" :readonly="false"
                    :disabled="false" :outlined="false" :clearable="false" :dense="true" :options="options1" />
                </v-col> -->

                <!-- storage -->
                <!-- <v-col cols="6" class="">
                  <v-text-field dense label="Storage" :error-messages="errors.storage" v-model="addOn.storage" ></v-text-field>
                </v-col> -->

                <!-- total user -->
                <!-- <v-col cols="6" class="">
                  <v-text-field type="number" dense label="Total User" :error-messages="errors.total_user" v-model="addOn.total_user" ></v-text-field>
                </v-col> -->


                <!-- storage price -->
                <!-- <v-col cols="6" class="">
                  <vuetify-money
                    v-model="addOn.storage_price"
                    label="Price"
                    placeholder="Input price"
                    :readonly="false"
                    :disabled="false"
                    :outlined="false"
                    :clearable="false"
                    :dense="true"
                    :options="options1"
                  />
                </v-col> -->

                <!-- is aol -->
                <!-- <v-col cols="6" class="">
                  <v-checkbox dense label="Is AOL" :error-messages="errors.is_aol" v-model="addOn.is_aol">
                  </v-checkbox>
                </v-col> -->

                <!-- ref modules -->
                <!-- <v-col cols="12" class="d-flex inline-flex">
                  <label class="mt-5">Ref Modules :</label>
                  <div class="ml-5 " v-for="(item, index) in refModules.data" :key="index">
                    <v-checkbox
                      v-model="module"
                      :value="item.id"
                      :label="item.name"

                      checked
                    ></v-checkbox>
                  </div>
                </v-col> -->

                <!-- sub modules -->
                <v-col cols="12" class="">
                  <!-- <div>
                    <label class="typo__label">Tagging</label>
                    <multiselect v-model="module" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" track-by="id" :options="dataOrder" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                    <pre class="language-json"><code>{{ module  }}</code></pre>
                  </div> -->
                  <!-- <v-select v-model="module" :items="dataOrder" label="Module" multiple item-text="name" item-value="id"
                    chips persistent-hint @change="getSubmodule()"></v-select> -->
                  <v-autocomplete filled dense :items="dataOrder" item-value="id"  item-text="name"
                        v-model="module" multiple
                        :search-input.sync="searchDataOrder" @change="getSubmodule()">
                  </v-autocomplete>
                  <!-- <v-row>
                    <v-col cols="3">
                      <v-card class="mx-auto" max-height="600px">
                        <v-list flat>
                          <v-list-item-group color="indigo">
                            <v-list-item v-for="(item, k) in dataOrder" :key="k">


                              <v-list-item-content>
                                <v-checkbox v-model="module" :value="item.id" :label="item.name"
                                  @change="getSubmodule(item.id,$event,k)" checked ref="rolesSelected"></v-checkbox>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-col>
                    <v-col cols="9">
                      <v-card class="overflow-y-auto" height="555px" max-height="600px" v-scroll.self="onScroll">
                          <v-list flat>
                            <v-list-item-group color="indigo">
                              <v-list-item id="" v-for="(item, index) in dataSubmodule" :key="index">


                                <v-list-item-content>
                                  <v-checkbox v-model="submodule" id="sub-module" :value="item.id" :label="item.name" >
                                  </v-checkbox>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                      </v-card>
                    </v-col>
                  </v-row> -->
                </v-col>

                <v-col cols="12" v-for="(element, k) in dataSubmodule" :key="k">
                  <h4 v-if="element.module == 1">
                    Marketing
                  </h4>
                  <h4 v-else-if="element.module == 2">
                    Operation
                  </h4>
                  <h4 v-else-if="element.module == 3">
                    Master
                  </h4>
                  <h4 v-else-if="element.module == 4">
                    Report
                  </h4>
                  <h4 v-else-if="element.module == 5">
                    Setting
                  </h4>
                  <h4 v-else-if="element.module == 6">
                    Internal
                  </h4>
                  <!-- <v-select v-model="submodule" :items="element.data" label="Choose Sub Module" multiple item-text="name" item-value="id"
                    chips persistent-hint @change="getItem()"></v-select> -->
                  <v-card v-if="status == true" max-height="400px" class="overflow-y-auto" v-scroll.self="onScroll">
                    <v-row>
                      <v-col cols="12">

                        <v-card-text>
                          <v-row v-for="(item, j) in element.data" :key="j">
                            <v-col cols="3" style="padding-bottom:0px !important">
                              <v-checkbox v-model="item.submodule" :value="item.id" :label="item.name">
                              </v-checkbox>
                            </v-col>
                            <v-col cols="2">
                               <vuetify-money v-model="item.price" label="Price (Rp)" placeholder="Input price" :readonly="false"
                                :disabled="false" :outlined="false" :clearable="false" :dense="true" :options="options1" />
                                
                                 <!-- <v-text-field type="number" dense label="Price" :error-messages="errors.total_user" v-model="item.price" @input="totalPrice(j)"></v-text-field> -->
                            </v-col>
                            <!-- <v-col cols="2">
                              <vuetify-money v-model="item.disc" label="Disc (Rp)" placeholder="Input price" :readonly="false"
                                :disabled="false" :outlined="false" :clearable="false" :dense="true" :options="options1" />
                            </v-col> -->
                              <v-col cols="2">
                                <label for="">Total</label>
                                <!-- <vuetify-money v-model="item.total" label="Price" placeholder="Input price" :readonly="false"
                                :disabled="false" :outlined="false" :clearable="false" :dense="true" :options="options1" /> -->
                                
                              </v-col>
                              <v-col cols="2" v-if="item.disc == null && item.price == null">
                               Rp  0
                              </v-col>
                              <v-col cols="2" v-else-if="item.disc == null && item.price != null">
                               Rp  {{item.price }}
                              </v-col>
                              <v-col cols="2" v-else-if="item.disc != null && item.price == null">
                               Rp  {{item.price }}
                              </v-col>
                              <v-col cols="2" v-else-if="item.disc != null && item.price != null">
                               Rp  {{item.price - item.disc}}
                              </v-col>
                              
                          </v-row>
                        </v-card-text>

                      </v-col>
                    </v-row>
                  </v-card>


                </v-col>
                <!-- description -->
                <v-col class="" cols="12">
                  <p>Description :</p>
                  <quill-editor v-model="addOn.description" :error-messages="errors.description" />
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
  import {
    mapActions,
    mapState
  } from "vuex";
  import axios from 'axios';
  // import Multiselect from 'vue-multiselect'

  import {
    quillEditor
  } from "vue-quill-editor";
// import { filter } from 'vue/types/umd';

//   function removeDuplicateObjectFromArray(array, key) {
//     let check = {};
//     let res = [];
//     for(let i=0; i<array.length; i++) {
//         if(!check[array[i][key]]){
//             check[array[i][key]] = true;
//             res.push(array[i]);
//         }
//     }
//     return res;
// }
  export default {
    components: {
      quillEditor,
      // Multiselect
    },
    data: () => ({
      options1: {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 9,
        precision: 0
      },
      items: [],
      searchRefModule: "",
      searchDataOrder: "",
      ref_module_id: "",
      name: "",
      description: "",
      refModule: "",
      status:false,
      module: [],
      newModule: [],
      dataOrder: [],
      subModuleArray:[],
      dataSubmodule: [{
        module:'',
        data:[{
          submodule:'',
          price:0,
          disc:0,
          total:0
        }]
      }],
      rules:{
        required: value => !!value || 'Required.'
      },
      submodule: [],
      chart:[],
      breads: [{
          text: 'Add On',
          disabled: false,
          href: 'add-on',
        },
        {
          text: 'Create add on',
          disabled: true,
          href: 'add-on/create',
        },
      ],
    }),
    props: {
      id: null,
    },
    watch: {
      searchDataOrder: function (newSearchDataOrder) {
        let params = {
          name: newSearchDataOrder,
        };
        this.$store.dispatch("refModule/searchRefModules", params);
        
      },
    },
    methods: {
      ...mapActions("addOn", ["getAddOn", "storeAddOn", "updateAddOn"]),
      ...mapActions("refModule", ["getRefModules"]),
      ...mapActions("subModule", ["getSubModule"]),
      totalPrice(j){
        console.log(j,'index')
      },

      getSubmodule() {
        let moduleArray = []
        console.log(this.module, 'isi module')
        moduleArray = this.module
        console.log(moduleArray , 'new module')
        this.dataSubmodule = []
        this.status = true
        this.submodule = []

        
        const seen = new Set();
        this.newModule = moduleArray.filter(el => {
          const duplicate = seen.has(el);
          seen.add(el);
          return !duplicate;
        });
        console.log(this.newModule,'module setelah remove duplicate')
        let array = this.newModule
        if(this.newModule != []){
        // var array2 = []

        // for (let i = 0; i < array.length; i++) {
        //   const element = array[i];
        //   axios.get('/sub-modules/' + element).then((response) => {
        //     console.log(response.data.data, 'rest sub module')
        //     array2.push({
        //       module: element,
        //       data: response.data.data
        //     })

        //   }, () => {
        //     console.log("gagal")
        //   });
        // }
        // var yuhu = ''
        array.forEach(element => {
          axios.get('/sub-modules/' + element).then((response) => {
            console.log(response.data.data, 'rest sub module')
            this.dataSubmodule.push({
              module: element,
              data: response.data.data
            })
            // this.subModuleArray = array2
            this.dataSubmodule = [...new Map(this.dataSubmodule.map(item => [item.module, item])).values()]
            
          }, () => {
            console.log("gagal")
          });
        });
        this.dataSubmodule = []
        console.log(this.subModuleArray,'submodule after set')
        // const uniqueObjects = [...new Map(this.subModuleArray.map(item => [item.module, item])).values()]
        // const seen2 = new Set();
        // this.dataSubmodule = this.subModuleArray.filter(el => {
        //   const duplicate2 = seen2.has(el.module);
        //   seen2.add(el.module);
        //   return !duplicate2;
        // });

        // console.log(uniqueObjects,'ini data submodule')
        // console.log(removeDuplicateObjectFromArray(this.subModuleArray, 'module'))
        
        }
      },
      addSubModule(id) {
        this.submodule.push({
          sub_module: id
        })

        console.log(this.submodule)
      },
      // getText(item){
      //   return '${item.name}'
      // },
      async init() {
        // this.getRefModules()
        axios.get('/ref-modules').then((response) => {
          this.dataOrder = response.data.data;
          // for (let j = 0; j < this.dataOrder.length; j++) {
          //   const element = this.dataOrder[j];

          //   axios.get('/sub-modules/'+element.id).then((response) => {
          //     console.log(response.data.data,'rest sub module')
          //     this.dataSubmodule = response.data.data

          //   }, () => {
          //     console.log("gagal")
          //   });
          // }

        }, () => {
          console.log("cant get ref module")
        });
        if (typeof this.id !== "undefined") {
          let res = await this.getAddOn(this.id);
          // this.dataOrder = 
          // this.module = 
          let arrayModule = []
          this.dataSubmodule = []
            for (let i = 0; i < res.product_item_details.length; i++) {
              arrayModule.push(res.product_item_details[i].ref_module_id)
            }
            const seen = new Set();
            this.module = arrayModule.filter(el => {
              const duplicate = seen.has(el);
              seen.add(el);
              return !duplicate;
            });
            
            var result = Object.entries(res.dataSubModule).map(([key, value]) => ({key,value}));
            console.log(result,'data submodule')
            for (let k = 0; k < result.length ; k++) {
              const element = result[k];
              this.dataSubmodule.push({
                'module' : element.key,
                'data'  : element.value
              })
              // this.module.push(element.key)
            }
            this.status = true
            // console.log(result);
            // console.log(this.dataSubmodule, 'array submodule')
            // console.log(this.module)
            // console.log(res,'respon')

            
        
        } else {
          this.$store.commit('addOn/SET_ADD_ON', {});
        }
      },
      store() {
        if (this.dataSubmodule.length < 1) {
          this.$swal({
            title: "Ref Submodule",
            text: "Ref Submodule tidak boleh kosong!",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          });
        } else {
          // this.addOn.module = this.module
          // this.storeAddOn(this.addOn)
          console.log(this.dataSubmodule)
          let arraySubmodule = []
          let totalDisc = 0
          let total = 0
          for (let i = 0; i < this.dataSubmodule.length; i++) {
            const element = this.dataSubmodule[i];
            console.log(element.data)
            for (let j = 0; j < element.data.length; j++) {
              const data = element.data[j];
                let getTotal = 0
                if(data.price == undefined){
                  data.price = 0;
                }
                // console.log(data.price,'dataPrice');
                // let total = 0
              if(data.submodule){
                // console.log(data.submodule, 'ada submodule')
                // console.log(data.disc, 'disc')
                if(typeof data.disc != 'undefined'){
                  getTotal = data.price - data.disc
                  total += getTotal
                  // console.log(total,'yes');
                  totalDisc = parseInt(totalDisc)+ parseInt(data.disc)
                  arraySubmodule.push(data)
                }else{
                  getTotal = data.price - 0
                  total += getTotal
                  totalDisc = parseInt(totalDisc)+ parseInt(0)
                  arraySubmodule.push(data)
                }
                
              }
            }
          }
          console.log(totalDisc)
          
          this.addOn.total = total
          this.addOn.totalDisc = totalDisc
          this.addOn.submodule = arraySubmodule
          // console.log(arraySubmodule)
          this.storeAddOn(this.addOn)
        }
          

      },
      update() {
        if (this.module.length < 1) {
          this.$swal({
            title: "Ref Module",
            text: "Ref Module tidak boleh kosong!",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          });
        } else {
          let arraySubmodule = []
          let total = 0
          let totalDisc = 0
          for (let i = 0; i < this.dataSubmodule.length; i++) {
            const element = this.dataSubmodule[i];
            console.log(element.data)
            for (let j = 0; j < element.data.length; j++) {
              const data = element.data[j];
              if(data.submodule){
                console.log(data, 'ada submodule')
                let getTotal = data.price - data.disc
                total += getTotal
                totalDisc = parseInt(totalDisc)+ parseInt(data.disc)
                arraySubmodule.push(data)
              }
            }
          }


          this.addOn.total = total
          this.addOn.totalDisc = totalDisc
          this.addOn.submodule = arraySubmodule
          this.updateAddOn(this.addOn)
        }
      }
    },
    computed: {
      ...mapState("addOn", ["addOn"]),
      ...mapState("refModule", ["refModules"]),
      ...mapState("subModule", ["subModule"]),
      ...mapState(["errors"]),


    },
    
    mounted() {
      this.init();

    },
  };
</script>

<style scoped>
  .set-height {
    height: 80px;
  }

  .ww {
    max-width: 50px;
  }

  /* .set-height-q{
  height: 400px;
} */
</style>