<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-5" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Data Release Note</h2>
            <!-- Add Button -->
          </v-card-title>
          <v-card-title>
            <!-- Search data -->
            <v-text-field
              append-icon="mdi-magnify"
              :items="releaseNotes"
              item-value="id"
              :search-input.sync="searchReleaseNote"
              label="Search"
              v-model="search"
              single-line
              hide-details
              class="font-weight-light"
            ></v-text-field>
            <v-btn
                rounded
                elevation="2"
                color="success"
                dark
                small
                class="text-capitalize font-weight-light white--text ml-5"
                @click="getSearchData">
              Search
            </v-btn>
            <!-- Add button -->
            <v-btn
              rounded
              elevation="2"
              color="info"
              dark
              small
              class="text-capitalize font-weight-light white--text ml-5"
              :to="{name: 'admin-release-note-create'}">
              Add
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Table -->
        <div>

          <v-simple-table>
            <thead>
            <tr>
              <th>No</th>
              <th>Date Schedule</th>
              <th>Version</th>
              <th>Aksi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in releaseNotes.data" :key="data.id">
              <td>{{ ((page-1)*10)+index+1 }}</td>
              <td>{{ ampmTime(data.date_schedule) }}</td>
              <td>{{ data.version }}</td>

              <td>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-btn
                        dark
                        elevation="0"
                        small
                        color="yellow darken-2"
                        class="text-capitalize font-weight-light"
                        :to="{name: 'admin-release-note-edit', params: { id: data.id }}"
                      >
                        edit
                      </v-btn>

                      <v-btn
                        dark
                        elevation="0"
                        small
                        color="error"
                        class="ml-3 text-capitalize font-weight-light"
                        @click="confirm(data)"
                      >
                        Delete
                      </v-btn>

                      <!-- preview modal -->
                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="600"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            color="cyan"
                            elevation="0"
                            small
                            class="ml-3 text-capitalize font-weight-light"
                            v-bind="attrs"
                            v-on="on"
                          >Preview</v-btn>
                        </template>

                        <template>

                          <v-card>
                            <!-- Header modal -->
                            <v-toolbar
                                color="primary"
                                dark
                            >Data Release Note</v-toolbar>

                            <!-- Modal content -->
                            <v-card-text class="mt-5">
                              <v-row align="center" justify="center">

                                <v-col cols="4">
                                  <v-text-field label="Date Schedule" dense outlined readonly :value="ampmTime(data.date_schedule)"></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                  <v-text-field label="Version" dense outlined readonly v-model="data.version"></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                  <label >Description :</label>
                                  <div v-html="data.description" class="text-h5"></div>
                                </v-col>
                              </v-row>
                            </v-card-text>

                            <!-- Modal footer (button) -->
                            <v-card-actions class="justify-end">
                              <!-- if unpublished -->
                              <v-btn
                                  v-if="data.status == 0"
                                  class="text-capitalize font-weight-light"
                                  color="primary"
                                  elevation="0"
                                  @click="onChangeStatus(data)"
                              >
                                <v-icon
                                    right
                                    dark
                                >
                                  mdi-refresh
                                </v-icon>
                                publish
                              </v-btn>

                              <!-- if published -->
                              <v-btn
                                  v-if="data.status == 1"
                                  class="text-capitalize font-weight-light"
                                  color="error"
                                  elevation="0"
                                  @click="onChangeStatus(data)"
                              >
                                <v-icon
                                    right
                                    dark
                                >
                                  mdi-refresh
                                </v-icon>
                                unpublish
                              </v-btn>
                            </v-card-actions>
                          </v-card>

                        </template>
                      </v-dialog>

                    </v-col>
                  </v-row>
                </v-col>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <!-- paginate -->
          <div class="text-right pb-10 mt-5">
            <v-pagination
                v-model="page"
                :length="totalPage"
                @change="getData()"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data () {
    return {
      page : 1 ,
      dialog: false,
      breads: [
        {
          text: 'Release Note',
          disabled: false,
          href: 'release-notes',
        },
        {
          text: 'Create release note',
          disabled: false,
          href: 'release-notes/create',
        },
      ],
      search: '',
      searchReleaseNote: '',
    }
  },
  computed: {
    ...mapState("releaseNote", ["releaseNotes"]),
    totalPage: function () {
      if(typeof this.releaseNotes.meta !== "undefined")
        return this.releaseNotes.meta.last_page
      return 1
    }
  },
  watch:{
    page : function (){
      this.getData();
    },
  },
  methods: {
    ...mapActions(["removeErrors"]),
    ...mapActions("releaseNote", ["getReleaseNotes", "changeStatus", "deleteReleaseNote"]),
    ampmTime(date){
      let formatDate = new Date(date);
      return formatDate.toLocaleString('en-US', { year:'numeric', month:'numeric', day:'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
    },
    async onChangeStatus(data)  {
      await this.changeStatus(data)
      this.$swal("Updated!", "Status has been updated", "success")
      this.getData();
    },
    confirm(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteReleaseNote(data.id);
          this.$swal("Deleted!", "Item Deleted", "success");
        }
      });
    },
    getData(){
      this.getReleaseNotes({page: this.page, search : this.search})
    },
    getSearchData(){
      this.page = 1
      this.getReleaseNotes({page: this.page, search : this.search})

    }
  },
  mounted() {
    this.getData();
    this.removeErrors();
  },
};
</script>

<style scoped>
.thead{
  background-color: #eeeeee;
}

.action-width{
  justify-content: left;
}
.search{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>