<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-5 text-h5 font-weight-light">Form Releas Note</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-release-note'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- version -->
                <v-col cols="2" class="set-height mt-3">
                  <v-text-field dense outlined label="Version" :error-messages="errors.version" v-model="releaseNote.version" ></v-text-field>
                </v-col>
                <!-- date schedule -->
                <v-col cols="5" class="set-height mt-3">
                  <v-text-field dense outlined label="Date Schedule" :error-messages="errors.date_schedule" v-model="releaseNote.date_schedule" type="date"></v-text-field>
                </v-col>
                <!-- time schedule -->
                <v-col cols="5" class="set-height mt-3">
                  <v-text-field dense outlined label="Time Schedule" :error-messages="errors.time_schedule" format="24hr" v-model="releaseNote.time_schedule" type="time"></v-text-field>
                </v-col>
                <!-- description -->
                <v-col  cols="12" class="set-height-q">
                  <p>Description :</p>
                  <quill-editor v-model="releaseNote.description" :error-messages="errors.description" />
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save</v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
// import { Datetime } from 'vue-datetime';
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";
import Swal from "sweetalert2";

export default {
  components: {
    quillEditor,
    // datetime: Datetime
  },
  data: () => ({
    date_schedule: "",
    version : "",
    description : "",
    breads: [
      {
        text: 'Releas Note',
        disabled: false,
        href: 'release-notes',
      },
      {
        text: 'Create release note',
        disabled: true,
        href: 'release-notes/create',
      },
    ],
  }),
  props: {
    id: null,
  },
  watch: {
    init() {
      console.log(this.time)
    }
  },
  methods: {
    ...mapActions("releaseNote", ["getReleaseNote","storeReleaseNote","updateReleaseNote"]),
    ...mapActions("company", ["getCompanies"]),
    async init(){
      if(typeof this.id !== "undefined"){
        let releaseNote = await this.getReleaseNote(this.id)
        let schedule = releaseNote.date_schedule;
        let words = schedule.split(' ')
        this.releaseNote.date_schedule = words[0]
        this.releaseNote.time_schedule = words[1]
      } else {
        this.$store.commit('releaseNote/SET_RELEASE_NOTE', {});
      }
    },
    store(){
      if(this.releaseNote.date_schedule == null || this.releaseNote.time_schedule == null)
        return Swal.fire("Error", 'Harap Isi tanggal dan waktu', "warning");
      this.releaseNote.date_schedule = this.releaseNote.date_schedule + " " + this.releaseNote.time_schedule
      this.storeReleaseNote(this.releaseNote)
    },
    update(){

      if(this.releaseNote.date_schedule == null || this.releaseNote.time_schedule == null)
        return Swal.fire("Error", 'Harap Isi tanggal dan waktu', "warning");
      this.releaseNote.date_schedule = this.releaseNote.date_schedule + " " + this.releaseNote.time_schedule
      this.updateReleaseNote(this.releaseNote)
    }
  },
  computed: {
    ...mapState("releaseNote", ["releaseNote"]),
    ...mapState(["errors"]),
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.set-height{
  height: 80px;
}

/* .set-height-q{
  height: 400px;
} */
</style>