import Voucher from "../../../api/MasterVoucher";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getVouchers = ({ commit }, payload) => {
    Voucher.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_VOUCHERS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
export const searchVouchers = ({ commit }, payload) => {
    Voucher.search(payload)
        .then((response) => {
            commit("SET_VOUCHERS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getVoucher = ({ commit }, payload) => {
    return Voucher.show(payload)
        .then((response) => {
            commit("SET_VOUCHER", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeVoucher = ({ dispatch }, payload) => {
    Voucher.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-master-voucher" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateVoucher = ({ dispatch }, payload) => {
    Voucher.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-master-voucher" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteVoucher = ({ commit }, payload) => {
    Voucher.delete(payload)
        .then(() => {
            commit("UNSET_VOUCHER", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};