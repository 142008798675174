import Setting from "../../../api/Setting";

export const getDestroySession = (payload) => {
    Setting.destroy_session(payload)
        .then((response) => {
            // commit("SET_ROLES", response.data.data);
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
};
