export const SET_PACKAGES = (state, payload) => {
    state.master_packages = payload;
};

export const SET_PACKAGE = (state, payload) => {
    state.master_package = payload;
};

export const UNSET_PACKAGE = (state, payload) => {
    state.master_packages.data = state.master_packages.data.filter((item) => {
        return item.id !== payload;
    });
};