export const SET_ADD_ONS = (state, payload) => {
    state.addOns = payload;
};

export const SET_ADD_ON = (state, payload) => {
    state.addOn = payload;
};

export const UNSET_ADD_ON = (state, payload) => {
    state.addOns.data = state.addOns.data.filter((item) => {
        return item.id !== payload;
    });
};