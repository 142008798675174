import UserStorage from "../../../api/UserStorage";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getUserStorages = ({ commit }, payload) => {
    UserStorage.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_USER_STORAGES", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchUserStorages = ({ commit }, payload) => {
    UserStorage.search(payload)
        .then((response) => {
            commit("SET_USER_STORAGES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const changeStatus = ({ dispatch }, payload) => {
    return UserStorage.changeStatus(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getUserStorage = ({ commit }, payload) => {
    return UserStorage.show(payload)
        .then((response) => {
            commit("SET_USER_STORAGE", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeUserStorage = ({ dispatch }, payload) => {
    UserStorage.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-user-storage" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateUserStorage = ({ dispatch }, payload) => {
    UserStorage.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-user-storage" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteUserStorage = ({ commit }, payload) => {
    UserStorage.delete(payload)
        .then(() => {
            commit("UNSET_USER_STORAGE", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};