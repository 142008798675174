<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :href="item.href"
              :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="mb-2" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Data Jobfile Other Type</h2>
          </v-card-title>
          <!-- <v-card-title>
            
          </v-card-title> -->
          <v-card-title>
            <!-- Search data -->
            <v-text-field
                append-icon="mdi-magnify"
                :items="jobfileOtherTypes"
                item-value="id"
                :search-input.sync="searchJobfileOtherType"
                label="Search"
                v-model="search"
                single-line
                hide-details
                class="font-weight-light"
            ></v-text-field>
            <v-btn
                rounded
                elevation="2"
                color="success"
                dark
                small
                class="text-capitalize font-weight-light white--text ml-5"
                @click="getSearchData">
              Search
            </v-btn>

            <!-- Add Button -->
            <v-btn
                rounded
                elevation="2"
                color="info"
                dark
                small
                class="text-capitalize font-weight-light white--text ml-5"
                :to="{name: 'admin-jobfile-other-type-create'}">
              Add
            </v-btn>

          </v-card-title>
        </v-row>
        <!-- Datatable -->
        <div>
          <v-simple-table>
            <thead>
            <tr>
              <th>No</th>
              <th>Type Name</th>
              <th>Reference Type</th>
              <th style="width: 20em;">Aksi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in jobfileOtherTypes.data" :key="data.id">
              <td>{{ ((page-1)*10)+index+1 }}</td>
              <td>{{ data.type_name }}</td>
              <td>{{ data.reference_type }}</td>
              <td>
                <v-col>
                    <v-row>
                        <v-btn
                          dark
                          elevation="0"
                          small
                          color="yellow darken-2"
                          class="text-capitalize font-weight-light"
                          :to="{name: 'admin-jobfile-other-type-edit', params: { id: data.id }}"
                        >
                          edit 
                        </v-btn>

                        <v-btn
                          dark
                          elevation="0"
                          small
                          color="error"
                          class="ml-3 text-capitalize font-weight-light"
                          @click="confirm(data)"
                        >
                          Delete
                        </v-btn>
                    </v-row>
                  </v-col>
              </td>
            </tr>
            </tbody>
          </v-simple-table>

          <div class="text-right pb-10 mt-5">
            <v-pagination
                v-model="page"
                :length="totalPage"
                @change="getData()"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data: () => ({
    page: 1,
    // speed dial button
      hover: 'false',
      fab: false,
      direction: 'right',
      transition: 'slide-x-transition',
    breads: [
      {
        text: 'Master Jobfile Other Type',
        disabled: false,
        href: 'jobfile-other-types',
      },
      {
        text: 'Create Jobfile Other Type',
        disabled: false,
        href: 'jobfile-other-types/create',
      },
    ],
    search: "",
    searchJobfileOtherType: '',
  }),
  watch: {
    page: function () {
      this.getData()

    },
  },
  computed: {
    ...mapState("jobfileOtherType", ["jobfileOtherTypes"]),
    totalPage: function () {
      if (typeof this.jobfileOtherTypes.meta !== "undefined")
        return this.jobfileOtherTypes.meta.last_page
      return 1
    }
  },
  methods: {
    ...mapActions(["removeErrors"]),
    ...mapActions("jobfileOtherType", ["getJobfileOtherTypes", "deleteJobfileOtherType"]),
    confirm(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteJobfileOtherType(data.id).then((res) => {
            console.log(res.data)
            if (res.data.code == 200)
              this.$swal("Deleted!", "Item Deleted", "success")
          });

        }
      });
    },
    getData() {
      this.getJobfileOtherTypes({page: this.page, search: this.search})
    },
    getSearchData() {
      this.page = 1
      this.getJobfileOtherTypes({page: this.page, search: this.search})

    }
  },
  mounted() {
    console.log(this.search)
    this.getData()
    this.removeErrors();
  },
};
</script>

<style scoped>
.thead {
  background-color: #eeeeee;
}

#heading-title {
  background-color: #eeeeee;
}

.search {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.relative{
  position: relative;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>