export const SET_SUB_MODULES = (state, payload) => {
    state.subModules = payload;
};

export const SET_SUB_MODULE = (state, payload) => {
    state.subModule = payload;
};

export const UNSET_SUB_MODULE = (state, payload) => {
    state.subModules.data = state.subModules.data.filter((item) => {
        return item.id !== payload;
    });
};