<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-5" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Data Product Item</h2>
            <!-- Add Button -->
          </v-card-title>
          <v-card-title>
            <!-- Search data -->
            <v-text-field
              append-icon="mdi-magnify"
              :items="productItems"
              item-value="id"
              :search-input.sync="searchProductItem"
              label="Search"
              v-model="search"
              single-line
              hide-details
              class="font-weight-light"
            ></v-text-field>
            <v-btn
                rounded
                elevation="2"
                color="success"
                dark
                small
                class="text-capitalize font-weight-light white--text ml-5"
                @click="getSearchData">
              Search
            </v-btn>
            <!-- Add button -->
            <v-btn
              rounded
              elevation="2"
              color="info"
              dark
              small
              class="text-capitalize font-weight-light white--text ml-5"
              :to="{name: 'admin-product-item-create'}">
              Add
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Table -->
        <div>
          <v-simple-table fixed-header>
            <thead>
            <tr>
              <th>No</th>
              <th>Product Name</th>
              <!-- <th>Storage</th> -->
              <th>Price</th>
              <!-- <th>Total User</th> -->
              <th style="width: 90px;">Aksi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in productItems.data" :key="data.id_company">
              <td>{{ ((page-1)*10)+index+1 }}</td>
              <td>{{ data.name }}</td>
              <!-- <td>{{ data.storage }}</td> -->
              <td>Rp.{{ data.price | numFormat }}</td>
              <!-- <td>{{ data.total_user }}</td> -->
              <td>
                <v-col>
                  <v-row>
                    <!-- show action botton -->
                    <v-card id="create">
                      <v-speed-dial
                        :direction="direction"
                        :transition="transition"
                      >
                      <template v-slot:activator>
                        <v-btn x-small color="info" dark fab style="left:0; margin-top: -4px;" >
                          <v-icon v-if="fab">
                            mdi-close
                          </v-icon>
                          <v-icon v-else>
                            mdi-dots-horizontal
                          </v-icon>
                        </v-btn>
                      </template>
                      
                      <!-- branch button -->
                      <v-card color="transparent" elevation="0" class="relative">
                        <v-row class="">
                          <v-col style="margin-left: -20px;">
                            <v-btn dark icon elevation="0" small color="warning" class="mt-12 text-capitalize font-weight-light" :to="{name: 'admin-product-item-edit', params: { id: data.id }}">
                              <v-icon>mdi-pencil-box-outline</v-icon>
                            </v-btn>
                            <v-btn
                              dark
                              icon
                              elevation="0"
                              small
                              color="error"
                              class="text-capitalize font-weight-light"
                              @click="confirm(data)"
                            >
                              <v-icon>mdi-delete-forever</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                      </v-speed-dial>
                    </v-card>
                  </v-row>
                </v-col>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <!-- paginate -->
          <div class="text-right pb-10 mt-5">
            <v-pagination
                v-model="page"
                :length="totalPage"
                @change="getData()"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data () {
    return {
      page : 1 ,
       // speed dial button
      hover: 'false',
      fab: false,
      direction: 'right',
      transition: 'slide-x-transition',
      breads: [
        {
          text: 'Product Item',
          disabled: false,
          href: 'product-item',
        },
        {
          text: 'Create product item',
          disabled: false,
          href: 'product-item/create',
        },
      ],
      search: '',
      searchProductItem: '',
    }
  },
  computed: {
    ...mapState("productItem", ["productItems"]),
    totalPage: function () {
      if(typeof this.productItems.meta !== "undefined")
        return this.productItems.meta.last_page
      return 1
    }
  },
  watch:{
    page : function (){
      this.getData();
    },
  },
  methods: {
    ...mapActions(["removeErrors"]),
    ...mapActions("productItem", ["getProductItems", "deleteProductItem"]),
    confirm(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteProductItem(data.id);
          this.$swal("Deleted!", "Item Deleted", "success");
        }
      });
    },

    getData(){
      this.getProductItems({page: this.page, search : this.search})
    },
    getSearchData(){
      this.page = 1
      this.getProductItems({page: this.page, search : this.search})
    }
  },
  mounted() {
    this.getData();
    this.removeErrors();
  },
};
</script>

<style scoped>
.thead{
  background-color: #eeeeee;
}

.action-width{
  justify-content: left;
}
.search{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.relative{
  position: relative;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>