import axios from 'axios'

const END_POINT = "/destroy-session";

export default {
    destroy_session() {
        return axios
            .get(`${END_POINT}`)
            .then((response) => (this.info = response));
    },
};