export const SET_BAR_IMAGE = (state, payload) => {
    state.barImage = payload
}

export const SET_DRAWER = (state, payload) => {
    state.drawer = payload
}

export const SET_ERRORS = (state, payload) => {
    state.errors = payload;
};

export const UNSET_ERRORS = (state) => {
    state.errors = [];
};