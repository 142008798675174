import ProductItem from "../../../api/ProductItem";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getProductItems = ({ commit }, payload) => {
    ProductItem.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_PRODUCT_ITEMS", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchProductItems = ({ commit }, payload) => {
    ProductItem.search(payload)
        .then((response) => {
            commit("SET_PRODUCT_ITEMS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getProductItem = async({ commit }, payload) => {
    return ProductItem.show(payload)
        .then((response) => {
            commit("SET_PRODUCT_ITEM", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeProductItem = ({ dispatch }, payload) => {
    ProductItem.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-product-item" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateProductItem = ({ dispatch }, payload) => {
    ProductItem.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-product-item" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteProductItem = ({ commit }, payload) => {
    ProductItem.delete(payload)
        .then(() => {
            commit("UNSET_PRODUCT_ITEM", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};