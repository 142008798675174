import axios from 'axios'

const END_POINT = "/companies";

export default {
    all(page, search) {
        return axios.get(`${END_POINT}?page=${page}&search=${search}`);
    },
    company_number(data) {
        console.log(data)
        return axios.post(`${END_POINT}/get_company_number`, data);
    },
    search(data) {
        return axios.post(`${END_POINT}/search`, data);
    },
    show(id) {
        return axios.get(`${END_POINT}/${id}`);
    },

    store(data) {
        return axios.post(`${END_POINT}/store`, data);
    },

    update(data) {
        return axios.put(`${END_POINT}/update/${data.id_company}`, data);
    },

    delete(id) {
        return axios.delete(`${END_POINT}/delete/${id}`);
    },
};