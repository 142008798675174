<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-5 text-h5 font-weight-light">Form Company</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-company'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <v-form @submit.prevent="!id ? storeCompany(company) : updateCompany(company)">
          <v-container>
            <v-col>
              <v-row>
                <!-- name company -->
                <v-col cols="3">
                  <v-text-field label="Company Name" dense outlined class="" :error-messages="errors.name_company"
                    v-model="company.name_company" @keyup="getCompanyData"></v-text-field>
                </v-col>
                <!-- phone company -->
                <v-col cols="3">
                  <v-text-field type="number" label="Phone Number" dense outlined :error-messages="errors.phone_company"
                    v-model="company.phone_company"></v-text-field>
                </v-col>
                <!-- code company -->
                <v-col cols="3">
                  <v-text-field class="" dense outlined label="Code" :error-messages="errors.code_company"
                    v-model="company.code_company"></v-text-field>
                </v-col>
                <!-- email -->
                <v-col cols="3">
                  <v-text-field class="" type="email" dense outlined label="Email" :error-messages="errors.email"
                    v-model="company.email"></v-text-field>
                </v-col>
                <!-- pic email -->
                <v-col cols="3">
                  <v-text-field class="" dense outlined label="PIC Email" :error-messages="errors.pic_email"
                    v-model="company.pic_email"></v-text-field>
                </v-col>
                <!-- pic name -->
                <v-col cols="3">
                  <v-text-field label="PIC Name" dense outlined :error-messages="errors.pic_name"
                    v-model="company.pic_name"></v-text-field>
                </v-col>
                <!-- fax -->
                <v-col cols="3">
                  <v-text-field class="" type="number" dense outlined label="Fax" :error-messages="errors.fax_number"
                    v-model="company.fax_number"></v-text-field>
                </v-col>
                <!-- pic phone -->
                <v-col cols="3">
                  <v-text-field type="number" label="PIC Phonenumber" dense outlined :error-messages="errors.pic_phone"
                    v-model="company.pic_phone"></v-text-field>
                </v-col>
                <!-- city -->
                <v-col cols="3">
                  <v-text-field class="" dense outlined label="Kota" :error-messages="errors.city"
                    v-model="company.city"></v-text-field>
                </v-col>
                <!-- province -->
                <v-col cols="3">
                  <v-text-field class="" dense outlined label="Provinsi" :error-messages="errors.province"
                    v-model="company.province"></v-text-field>
                </v-col>
                <!-- country -->
                <v-col cols="3">
                  <v-text-field class="" dense outlined label="Negara" :error-messages="errors.country"
                    v-model="company.country"></v-text-field>
                </v-col>
                <!-- npwp -->
                <v-col cols="3">
                  <v-text-field class="" type="number" dense outlined label="NPWP" :error-messages="errors.npwp"
                    v-model="company.npwp"></v-text-field>
                </v-col>
                <!-- address company -->
                <v-col cols="12">
                  <v-textarea label="Company Address" dense outlined :error-messages="errors.address_company"
                    v-model="company.address_company">Address</v-textarea>
                </v-col>
                <v-col  cols="3">
                <v-autocomplete v-model="company.status_type_job_order" item-text="name" item-value="id"
                  :items="itemsTypeStatus" outlined dense label="Status Type">
                </v-autocomplete>
                </v-col>
                <v-col cols="3" class="">
                  <v-checkbox dense label="Active Jobfile Other Type" :error-messages="errors.active_jobfile_other_type" v-model="company.active_jobfile_other_type" @change="company.id_other_type = []" >
                  </v-checkbox>
                </v-col>
                <v-col  cols="3" v-if="company.active_jobfile_other_type == true">
                  <v-autocomplete label="Select Jobfile Other Types" :items="jobfileOtherTypes" item-value="id" :error-messages="errors.id_other_type"
                    v-model="company.id_other_type" :search-input.sync="searchOtherType" multiple style="margin-bottom: 10px;">
                  </v-autocomplete>
                </v-col>
                <v-col  cols="3">
                  <v-checkbox dense label="Invoice Multifile" v-model="company.invoice_multifile"></v-checkbox>
                </v-col>

                <!--                <v-checkbox-->
                <!--                    v-model="company.status_company"-->
                <!--                    label="Status Perusahaan"-->
                <!--                    false-value="0"-->
                <!--                    true-value="1"-->
                <!--                    :value="company.status_company"-->
                <!--                ></v-checkbox>-->
                <v-col>
                  <!-- button submit -->
                  <div class="text-right">
                    <v-btn color="blue" type="submit" class="button-save">Save</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>

          </v-container>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {
  },
  data: () => ({
    itemsTypeStatus: [{
      'id': 0,
      'name': 'Default'
    }, {
      'id': 1,
      'name': 'Type 1'
    }, {
      'id': 2,
      'name': 'Type 2'
    }, {
      'id': 3,
      'name': 'Type 3'
    }, {
      'id': 4,
      'name': 'Type 4'
    }, {
      'id': 5,
      'name': 'Type 5'
    }, {
      'id': 6,
      'name': 'Type 6'
  }],

    breads: [
      {
        text: 'Company',
        disabled: false,
        href: 'companies',
      },
      {
        text: 'Create company',
        disabled: true,
        href: 'companies/create',
      },
    ],
  }),
  props: {
    id: null,
  },

  methods: {
    ...mapActions("company", ["getCompany", "storeCompany", "updateCompany", "getCompanyNumber"]),
    ...mapActions("jobfileOtherType", ["searchJobfileOtherType"]),
    init() {
      if (typeof this.id !== "undefined") {
        this.getCompany(this.id);
      } else {
        this.$store.commit('company/SET_COMPANY', {});
      }

      this.searchJobfileOtherType({page: this.page, search: this.search})
      console.log(this.jobfileOtherTypes, 'this dataaa');
    },
    async getCompanyData() {
      let data = { name: this.company.name_company }
      console.log(data)
      let company_number = await this.getCompanyNumber(data);
      console.log(company_number)
      this.company.code_company = company_number
      return company_number
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState("company", ["company"]),
    ...mapState(["errors"]),
    ...mapState("jobfileOtherType", ["jobfileOtherTypes"]),
  },
};
</script>

<style scoped>
.text-code {
  width: 150px;
}

.text-address:focus {
  color: white;
}

.text-name {
  width: 350px;
}

.height {
  height: 50px;
}

/* .container{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
} */

.button-save {
  width: 150px;
  color: white;
  background-color: blue;
}

.button-save:hover {
  width: 150px;
  color: white;
}

/* #heading-title{
  background-color: #444D54;
  border-bottom: #EEEEEE solid 25px;
} */
</style>