import RbacRole from "@/api/RbacRole";

export const searchRbacRoles = ({ commit }, payload) => {
    RbacRole.search(payload)
        .then((response) => {
            commit("SET_RBAC_ROLES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
