import axios from 'axios'

const END_POINT = "/orders";

export default {
    all(page, search) {
        return axios.get(`${END_POINT}?page=${page}&search=${search}`);
    },

    show(id) {
        return axios.get(`${END_POINT}/${id}`);
    },
    search(data) {
        return axios.post(`${END_POINT}/search`, data);
    },

    invoice_number() {
        return axios.get(`${END_POINT}/get_invoice_number`);
    },

    invoice(id) {
        return axios.get(`${END_POINT}/pdf/${id}`);
    },

    payment_proof(data) {
        return axios.post(`${END_POINT}/payment_proof`, data);
    },

    store(data) {
        return axios.post(`${END_POINT}/store`, data);
    },

    send_inv(data) {
        return axios.post(`${END_POINT}/send_inv`, data);
    },

    cancel_order(data) {
        return axios.post(`${END_POINT}/cancel_order`, data);
    },

    update(data) {
        return axios.put(`${END_POINT}/update/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${END_POINT}/delete/${id}`);
    },
};