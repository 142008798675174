export const SET_ROLES = (state, payload) => {
    state.roles = payload;
};

export const SET_ROLE = (state, payload) => {
    state.role = payload;
};

export const UNSET_ROLE = (state, payload) => {
    state.roles = state.roles.filter((item) => {
        return item.id !== payload;
    });
};