import SubModule from "../../../api/SubModule";



export const getSubModule = async({ commit }, payload) => {
    return SubModule.show(payload)
        .then((response) => {
            commit("SET_SUB_MODULE", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchSubModules = ({ commit }, payload) => {
    SubModule.search(payload)
        .then((response) => {
            commit("SET_SUB_MODULES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
