<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-icon>mdi-login</v-icon>Login
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form @submit.prevent="login">
              <v-text-field
                label="email"
                v-model="email"
                type="email"
              ></v-text-field>

              <v-text-field
                label="password"
                v-model="password"
                type="password"
              ></v-text-field>

            <div class="text-right">
              <v-btn color="primary" type="submit">Login</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import router from "@/router";

export default {
  name: 'Login',
  components: {
    
  },
  data: () => ({
    email:"",
    password:""
  }),
  methods: {
    login () {
      let url = 'login-admin'
      let data = {
        email: this.email,
        password: this.password
      }
      axios.post(url, data)
          .then(res => {
            localStorage.setItem("token",res.data.data.token)
            axios.defaults.headers.common = {
              "Authorization": "Bearer " + res.data.data.token,
            };
            router.push({ name: 'admin-dashboard'})
          })
    }
  }
}
</script>

<style>

</style>