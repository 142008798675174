export const SET_BRANCHS = (state, payload) => {
    state.branchs = payload;
};

export const SET_BRANCH = (state, payload) => {
    state.branch = payload;
};

export const UNSET_BRANCH = (state, payload) => {
    state.branchs = state.branchs.filter((item) => {
        return item.id !== payload;
    });
};