<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>
      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-5 text-h5 font-weight-light">Form Order</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{ name: 'admin-order' }">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? store(order) : update(order)">
            <v-container>
              <v-row>
                <!-- select company -->
                <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Select Company </span>
                  <v-autocomplete dense  :items="companies" item-value="id" :error-messages="errors.id_company"
                    v-model="order.id_company" :search-input.sync="searchCompany" @change="getOrderList">
                  </v-autocomplete>
                </v-col>

                <!-- select order -->
                <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Select Order </span>
                  <v-autocomplete :readonly="ifSelectOrder === true ? false : true"  dense :items="orders" item-value="id" :error-messages="errors.id_order"
                    v-model="order.id_order" :search-input.sync="searchOrder" @change="getPrevOrder"></v-autocomplete>

                <!-- invoice number -->
                </v-col>
                <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Invoice Number </span>
                  <v-text-field  readonly  dense :error-messages="errors.invoice_number"
                    v-model="order.invoice_number"></v-text-field>
                </v-col>

                <!-- start date -->
                <!-- <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Start Date </span>
                  <v-text-field  :readonly="ifOrder === true ? true : false" dense  :error-messages="errors.start_period" v-model="order.start_period"
                    type="date" @input="getEndDate"></v-text-field>
                </v-col> -->

                <!-- jumlah bulan -->
                <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Jumlah Bulan </span>
                  <v-text-field :readonly="ifOrder === true ? true : false"  type="number" :class="{invalid:order.total_month < 1}" dense
                    :error-messages="errors.total_month" v-model="order.total_month" @input="getEndDate"></v-text-field>
                </v-col>

                <!-- end date -->
                <!-- <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> End Date </span>
                  <v-text-field readonly dense  :error-messages="errors.end_period" v-model="order.end_period"
                    type="date"></v-text-field>
                </v-col> -->

                <!-- remarks -->
                <v-col cols="12">
                  <span class="my-4 subtitle-1"> Remarks </span>
                  <v-textarea   name="input-7-4" v-model="order.remarks"></v-textarea>
                </v-col>

                <!-- select product -->
                <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Select Product </span>
                  <v-autocomplete :readonly="ifOrder === true ? true : false"  dense :items="productItems" item-value="id"
                    :error-messages="errors.id_product_item" v-model="order.id_product_item"
                    :search-input.sync="searchProductItem" @change="getProducts"></v-autocomplete>
                </v-col>

                <!-- gap -->
                <v-col  cols="4" class="set-height"> 
                  <!-- <div v-if="statusProduct == true">
                    Total Potongan  
                  <p>
                   Rp.  {{totalDiscProduct}}
                  </p>
                  </div> -->
                  
                </v-col>

                <!-- Price -->
                <v-col cols="4" align-self="right" class="text-end set-height">
                  <span class="my-4 subtitle-1"> Prices </span> <br />
                  <span class="my-4 subtitle-1"> Rp. {{priceProduct}} </span>
                </v-col>

                <!-- Select User Package -->
                <v-col cols="4" class="set-height mt-4">
                  <span class="my-4 subtitle-1"> Select User Package </span>
                  <v-autocomplete dense  :items="userPackages" item-value="id"
                    :error-messages="errors.id_user_package" v-model="order.id_user_package"
                    :search-input.sync="searchUserPackage" @change="getUserPackagePrice"></v-autocomplete>
                </v-col>
                <p style="margin-top: 65px">x</p>

                <!-- quantity -->
                <v-col cols="2" class="set-height mt-4">
                  <span class="my-4 subtitle-1"> Quantity </span>
                  <v-text-field  type="number" dense :error-messages="errors.qty_user_package"
                    v-model="order.qty_user_package" @input="getUserPackagePrice"></v-text-field>
                </v-col>
                <v-col cols="2" class="set-height mt-4">
                  <!-- <span class="my-4 subtitle-1"> Disc (Rp)</span>
                  <v-text-field  type="number" dense :error-messages="errors.disc_user_package"
                    v-model="order.disc_user_package" @input="getUserPackagePrice"></v-text-field> -->
                </v-col>

                <v-col style="float: right" cols="3.5" class="text-end set-height mt-4">
                  <span class="my-4 subtitle-1"> </span> <br />
                  <span class="my-4 subtitle-1"> Rp. {{priceUserPackage}} </span>
                </v-col>

                <!-- Select Storage Package -->
                <v-col cols="4" class="set-height">
                  <span class="my-4 subtitle-1"> Select Storage Package </span>
                  <v-autocomplete dense  :items="userStorages" item-value="id"
                    :error-messages="errors.id_storage_package" v-model="order.id_storage_package"
                    :search-input.sync="searchUserStorage" @change="getStoragePackagePrice"></v-autocomplete>
                </v-col>  
                <p style="margin-top: 50px; position: ">x</p>
                <!-- quantity -->
                <v-col cols="2" class="set-height">
                  <span class="my-4 subtitle-1"> Quantity </span>
                  <v-text-field  dense type="number" v-model="order.qty_storage_package"
                    :error-messages="errors.qty_storage_package" @input="getStoragePackagePrice"></v-text-field>
                </v-col>
                <v-col cols="2" class="set-height">
                  <!-- <span class="my-4 subtitle-1"> Disc </span>
                  <v-text-field  dense type="number" v-model="order.disc_storage_package"
                    :error-messages="errors.disc_storage_package" @input="getStoragePackagePrice"></v-text-field> -->
                </v-col>
                <v-col style="float: right" cols="3.5" class="text-end set-height">
                  <span class="my-4 subtitle-1"> </span> <br />
                  <span class="my-4 subtitle-1"> Rp. {{priceStoragePackage}} </span>
                </v-col>




                <v-col cols="12" v-for="(item, k) in order.extra_package" :key="k" no-gutters>
                  <v-row>

                    <!-- extra charge -->
                    <v-col cols="4" class="set-height">
                      <span class="my-4 subtitle-1">Select Extra Charge </span>
                      <v-autocomplete  dense :items="extraPackages" item-value="id"
                        v-model="item.id_extra_package"
                        :search-input.sync="searchExtraPackage" @change="getExtraPackagePrice(k)">
                      </v-autocomplete>
                    </v-col>

                    <!-- <v-col cols="2" class="set-height">
                      <span class="my-4 subtitle-1"> Disc </span>
                      <v-text-field  dense type="number" v-model="item.disc_extra_package"
                    :error-messages="errors.disc_extra_package" @input="getExtraPackagePrice(k)"></v-text-field>
                    </v-col> -->

                    <!-- gap -->
                    <v-col cols="6" class="set-height">
                      <span class="my-4 subtitle-1"> </span> <br />
                      <span class="subtitle-1">
                        <v-btn v-show="k || (!k && order.extra_package.length > 1)" @click="removeRemarks(k)" small
                          color="error" dark>

                          Hapus
                        </v-btn>
                        <!-- <a v-show="k || (!k && order.extra_package.length > 1)" href="javascript:void(0)"
                          class="button" @click="removeRemarks(k)"> hapus
                        </a>  -->
                        &nbsp;
                        <v-btn v-show="k === order.extra_package.length - 1" @click="addRemarks" small
                          color="info" dark>
                          Tambah
                        </v-btn>
                        <!-- <a v-show="k === order.extra_package.length - 1" href="javascript:void(0)" class="button"
                          @click="addRemarks">add</a> -->
                        &nbsp;
                      </span>
                    </v-col>

                    <!-- Price -->
                    <v-col cols="2" class="text-end set-height">
                      <span class="my-4 subtitle-1"> </span> <br />
                      <span class="my-4 subtitle-1"> Rp. {{item.price_extra_package}} </span>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="4" class="set-height" >
                  <span class="my-4 subtitle-1">Select AddOn </span>
                  <v-autocomplete :items="addOns" item-value="id" :search-input.sync="searchAddOn" style="max-height:70px; overflow-x:hidden; overflow-y:scroll; padding-left:10px" v-model="arrayAddOn" multiple>
                    </v-autocomplete>
                </v-col>
                <v-col  cols="4" class="set-height"> 
                  <!-- <div v-if="statusProduct == true">
                    Total Potongan  
                  <p>
                    Rp.  {{totalDiscProduct}}
                  </p>
                  </div> -->
                  
                </v-col>

                <!-- Price -->
                <v-col style="float: right" cols="3.5" class="text-end set-height mt-4">
                  <!-- <span class="my-4 subtitle-1"> </span> <br />
                  <span class="my-4 subtitle-1"> Rp. 0</span> -->
                </v-col>
                  <br />

                <!-- select voucher diskon -->
                <v-col cols="4" class="set-height mt-4">
                  <span class="my-4 subtitle-1"> Select Voucher Diskon </span>
                  <v-autocomplete dense  :items="vouchers" item-value="id"
                    :error-messages="errors.id_master_diskon" v-model="order.id_master_diskon"
                    :search-input.sync="searchVoucher" @change="getVoucherDiskon"></v-autocomplete>
                </v-col>

                <v-col cols="4"  class="set-height mt-4">
                  <span>
                    {{ descVoucher }}
                  </span>
                </v-col>

                <!-- <v-col style="float: right" cols="4" class="text-end set-height">
                  <span class="my-4 subtitle-1"> </span> <br />
                  <span class="my-4 subtitle-1"> Rp. {{nominalDisc}} </span>
                </v-col> -->

                <!-- Border -->
                <v-col cols="12" style="padding: 0 12px 0 12px; height: 0px" class="set-height mt-5">
                  <div style="border-top: 2px solid black"></div>
                </v-col>

                <!-- Total Harga -->
                <v-col cols="8" class="text-end">
                  <span class="subtitle-1"> Total Harga </span>
                </v-col>

                <!-- Price -->
                <v-col cols="4" class="text-end">
                  <span class="subtitle-1"> Rp. {{parseInt(totalPrice)}} </span>
                </v-col>

                <!-- Total Harga Sebelumnya-->
                <v-col v-if="ifOrder == true" cols="8" class="text-end">
                  <span class="subtitle-1"> Total Harga Sebelumnya </span>
                </v-col>

                <!-- Price -->
                <v-col v-if="ifOrder == true" cols="4" class="text-end">
                  <span class="subtitle-1"> Rp. {{totalHargaSebelumnya}} </span>
                </v-col>

                <!-- Potongan Harga Sebelumnya-->
                <!-- <v-col cols="8" class="text-end">
                  <span class="subtitle-1"> Potongan Harga Sebelumnya </span>
                </v-col> -->

                <!-- Price -->
                <!-- <v-col cols="4" class="text-end">
                  <span class="subtitle-1"> Rp. 0 </span>
                </v-col> -->

                <!-- Total Prorate-->
                <v-col v-if="ifOrder == true" cols="8" class="text-end">
                  <span class="subtitle-1"> Total Prorate </span>
                </v-col>

                <!-- Price -->
                <v-col v-if="ifOrder == true" cols="4" class="text-end">
                  <span class="subtitle-1"> Rp. {{totalProrateOrderBaru}} </span>
                </v-col>

                <!-- Sisa Bayar-->
                <v-col v-if="ifOrder == true" cols="8" class="text-end">
                  <span class="subtitle-1"> Sisa Bayar </span>
                </v-col>

                <!-- Price -->
                <v-col v-if="ifOrder == true" cols="4" class="text-end">
                  <span class="subtitle-1"> Rp. {{sisaBayar}} </span>
                </v-col>
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
  import {
    mapActions,
    mapState
  } from "vuex";
  import moment from 'moment';
  import axios from 'axios';
  // import { getAddOn } from "@/src/store/modules/add-on/actions";

  export default {
    components: {},
    data: () => ({
      items: [],
      packages: "",
      searchProductItem: "",
      searchUserPackage: "",
      searchUserStorage: "",
      searchOrder: "",
      searchVoucher:"",
      searchExtraPackage: "",
      id_company: "",
      searchCompany: "",
      total_user: "",
      start_period: "",
      end_period: "",
      invoice_number: "",
      payment_proof: "",
      remarks: "",
      file: "",
      url: "",
      totalHargaSebelumnya:0,
      totalDaysPrevOrder:0,
      sisaHari:0,
      priceProduct: 0,
      totalDiscProduct:0,
      statusProduct:false,
      priceUserPackage: 0,
      priceStoragePackage: 0,
      priceExtraPackage: 0,
      totalPrice: 0,
      ifOrder:false,
      ifSelectOrder:false,
      hariTerpakai:0,
      totalProrateOrderLama:0,
      totalProrateOrderBaru:0,
      totalPricePrevOrder:0,
      sisaBayar:0,
      descVoucher:'',
      discForOrder:0,
      discForProduct:0,
      discForUserPackage:0,
      searchAddOn:"",
      id_addon: [],
      arrayAddOn: [],
      order: {
        qty_user_package: 1,
        disc_user_package:null,
        qty_storage_package: 1,
        disc_storage_package:null,
        total_month: 1,
        id_company: "",
        id_order: null,
        extra_package: [{
          id_extra_package: '',
          // name_extra_package: '',
          price_extra_package: 0,
          disc_extra_package:null
        }]

      },
      dateNow:null,
      endDate:null,
      // jmlHari
      // remarks: [{
      //   value: ''
      // }],
      // addOns:[],
      breads: [{
          text: "Order",
          disabled: false,
          href: "orders",
        },
        {
          text: "Create order",
          disabled: true,
          href: "orders/create",
        },
      ],
    }),
    props: {
      id: null,

    },
    watch: {
      searchCompany: function (searchCompany) {
        let params = {
          name: searchCompany,
        };
        this.$store.dispatch("company/searchCompanies", params);
      },
      searchProductItem: function (newSearchPackage) {
        let params = {
          name: newSearchPackage,
        };
        this.$store.dispatch("productItem/searchProductItems", params);
      },
      searchUserPackage: function (newSearchUser) {
        let params = {
          name: newSearchUser,
        };
        this.$store.dispatch("userPackage/searchUserPackages", params);
      },
      searchUserStorage: function (newSearchUserStorage) {
        let params = {
          name: newSearchUserStorage,
        };
        this.$store.dispatch("userStorage/searchUserStorages", params);
      },
      searchOrder: function (newSearchOrder) {
        let params = {
          name: newSearchOrder,
          id_company: this.order.id_company
        };
        this.$store.dispatch("order/searchOrders", params);
      },
      searchExtraPackage: function (newSearchExtraPackage) {
        let params = {
          name: newSearchExtraPackage,
        };
        this.$store.dispatch("extraPackage/searchExtraPackages", params);
      },
      searchVoucher: function (newSearchVoucher) {
        let params = {
          name: newSearchVoucher,
        };
        this.$store.dispatch("masterVoucher/searchVouchers", params);
        // if(this.order.id_master_diskon !== 'undefined'){
        //   this.getVoucherDiskon()

        // }
      },
      searchAddOn: function (searchAddOn) {
        let params = {
          name: searchAddOn,
        };
        this.$store.dispatch("addOn/searchAddOns", params);
    },

    },
    methods: {
      ...mapActions("order", [
        "getOrder",
        "storeOrder",
        "updateOrder",
        "getInvoiceNumber",
        "getPaymentProof",
        "searchOrders",
      ]),
      ...mapActions("company", ["getCompanies"]),
      // ...mapActions("order", []),
      ...mapActions("userPackage", ["getUserPackages", "getUserPackage"]),
      ...mapActions("masterVoucher", ["getVouchers", "getVoucher"]),
      ...mapActions("userStorage", ["getUserStorages", 'getUserStorage']),
      ...mapActions("productItem", ["getProductItems", "getProductItem"]),
      ...mapActions("extraPackage", ["getExtraPackages", "getExtraPackage"]),
      ...mapActions("addOn", ["getAddOns"]),
      async paymentProof() {
        console.log(this.$refs.file.files[0]);
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("file", this.file);
        let payment_approve = await this.getPaymentProof(formData);
        this.order.payment_proof = payment_approve.path;
        this.order.image_payment_proof = payment_approve.uri;
        this.url = payment_approve.uri;
        console.log(payment_approve);
      },
      update(order) {
        // console.log(order,'data update')
        order.price_product_item = this.priceProduct
        order.price_user_package = this.priceUserPackage
        order.price_storage_package = this.priceStoragePackage
        order.price_extra_package = this.priceExtraPackage
        order.array_addon = this.arrayAddOn
        this.updateOrder(order);
      },
      store(order) {
        order.packages = this.searchProductItem
        order.price_product_item = this.priceProduct
        order.price_user_package = this.priceUserPackage
        order.price_storage_package = this.priceStoragePackage
        order.price_extra_package = this.priceExtraPackage
        order.array_addon = this.arrayAddOn

        // console.log(order,'saveOrder')
        this.storeOrder(order);
      },
      async init() {
        if (typeof this.id !== "undefined") {
          let orderData = await this.getOrder(this.id);
          
          await this.getProductItem(orderData.packages.id);
          this.addOnGet(orderData.packages.id);
          // console.log(orderData.packages.id,'idPackages')
          // console.log(this.id,'id')
          // console.log(orderData,'order data')
          this.order = orderData
          this.order.id_product_item = orderData.productItem
          this.order.id_user_package = orderData.userPackage
          this.order.disc_user_package = orderData.disc_user_package
          this.order.id_storage_package = orderData.storagePackage
          this.order.id_order = orderData.id_order
          this.order.id_master_diskon = orderData.id_master_voucher
          
          this.priceProduct = orderData.price_product_item
          this.priceUserPackage = orderData.price_user_package
          this.priceStoragePackage = orderData.price_storage_package

          if(this.order.extra_package == []){
            if (!this.order.extra_package) {
              return
            }
            this.order.extra_package.push({
              id_extra_package: '',
              // name_extra_package: '',
              price_extra_package: 0  
              // price_extra_package: 0
            })
          }
          let getVoucher = await this.getVoucher(orderData.id_master_voucher)
          console.log(getVoucher)
          this.url = orderData.image_payment_proof;
          this.calculatePrice()
        } else {
          this.$store.commit("order/SET_ORDER", {});
          let invoice_number = await this.getInvoiceNumber();
          this.order.invoice_number = invoice_number;
        }
      },

      async addOnGet(id_products){
        let res = await this.getProductItem(id_products);
        // console.log(res,'resAddon');
        let arrayAddOn = []
          for (let x = 0; x < res.addOns.length; x++) {
            const element = res.addOns[x];
            arrayAddOn.push(element.add_on_id)
          }

          const seenAddOn = new Set();
          this.arrayAddOn = arrayAddOn.filter(el => {
            const duplicate = seenAddOn.has(el);
            seenAddOn.add(el);
            return !duplicate;
          });
        // this.addOns(res) 
        // console.log(this.arrayAddOn,'isi array add on')
      },

      addRemarks() {
        if (!this.order.extra_package) {
          return
        }
        this.order.extra_package.push({
          // value: ''
          id_extra_package: '',
              // name_extra_package: '',
          price_extra_package: 0
        });



      },
      removeRemarks(x) {
        // console.log(x, 'index removeremarks')
        this.order.extra_package.splice(x, 1)
        this.saveRemarks()
      },
      saveRemarks() {
        let parsed = JSON.stringify(this.order.extra_package);

        localStorage.setItem('Remarks', parsed)
      },

      getOrderList: function () {
        // console.log(newSearchOrder, 'name order')
        let params = {
          // name: newSearchOrder,
          id_company: this.order.id_company
        };
        this.$store.dispatch("order/searchOrders", params);
        // console.log(this.$store.state.order.orders,'state order')

        axios.post('/orders/search', params).then((response) => {
          console.log(response.data.data,'rest order')
          if(response.data.data.length === 0 ){
            this.ifSelectOrder = false
          }else{
            this.ifSelectOrder = true
          }
        }, () => {
          console.log("gagal")
        });

      },



      getEndDate() {
        // console.log(this.order.start_period)
        // var jml_bulan = this.order.total_month
        // var endDate = moment(this.order.start_period).add(jml_bulan, 'month').format('YYYY-MM-DD');
        // this.order.end_period = endDate

        // var startDate = moment(this.order.start_period)
        // var dateEnd = moment(this.order.start_period).add(jml_bulan, 'month')

        // var jmlHari = dateEnd.diff(startDate, 'days')
        // console.log(jmlHari,' total jumlah hari')

        this.getPriceProduct()
        this.getUserPackagePrice()
        this.getStoragePackagePrice()
        this.getExtraPackagePrice()
      },

      async getPrevOrder() {
        var idOrder = this.order.id_order
        if (idOrder !== "undefined") {
          this.ifOrder = true;
          // console.log(idOrder)
          let dataOrder = await this.getOrder(idOrder);

          // this.order.disc_user_package = dataOrder.disc_user_package
          this.order.start_period = dataOrder.start_period
          this.order.end_period = dataOrder.end_period
          this.order.id_product_item = dataOrder.productItem
          this.priceProduct = parseInt(dataOrder.price_product_item)
          this.order.total_month = dataOrder.total_month

          this.dateNow = moment();
          this.endDate = moment(dataOrder.end_period).add(1,'days');

          this.sisaHari = this.endDate.diff(this.dateNow, 'days');

          this.hariTerpakai = this.dateNow.diff(dataOrder.start_period, 'days');

          var dateEnd = moment(this.order.start_period).add(dataOrder.total_month, 'month')

          // var jmlHari =
          this.totalDaysPrevOrder = dateEnd.diff(dataOrder.start_period, 'days')
          console.log(this.totalDaysPrevOrder, 'total hari')

          this.totalPricePrevOrder =  Math.ceil((this.priceProduct /this.totalDaysPrevOrder) * this.hariTerpakai);
          console.log(this.totalPricePrevOrder,'total price sebelumnya')

          // var startDate = moment(this.order.start_period)

          // this.totalDaysPrevOrder = this.totalDaysPrevOrder * 30
          // total harga order sebelumnya
          this.totalHargaSebelumnya = parseInt(this.totalHargaSebelumnya) + parseInt(dataOrder.price_product_item) + parseInt(dataOrder.price_storage_package) + parseInt(dataOrder.price_extra_package) + parseInt(dataOrder.price_user_package)

          // get total prorate order lama
          this.totalProrateOrderLama = Math.ceil((this.totalHargaSebelumnya /this.totalDaysPrevOrder) * this.hariTerpakai);
          console.log(this.totalProrateOrderLama, 'prorate order lama')
          // console.log(this.totalHargaSebelumnya,'total harga sebelumnya')

        }

        this.calculatePrice();

      },
      async getProducts(){
        this.getPriceProduct()
        this.addOnGet(this.order.id_product_item)
      },
      async getPriceProduct() {

        if (typeof this.order.id_product_item !== "undefined") {
          let productData = await this.getProductItem(this.order.id_product_item);
          // console.log(productData,'productData');
          
          
          // for (let i = 0; i < productData.length; i++) {
          //   const element = productData[i];
          //   this.addOns.push(element.add_on_id)
          // }
          if (productData.price !== "") {
            // console.log(productData)
            this.statusProduct = true
            this.totalDiscProduct += productData.total_disc
            // if(this.order.id_order !== null && this.ifOrder == true){

            //   console.log(this.order.id_order , 'order lama')


            //   if(this.dateNow < this.endDate){
            //       console.log(this.priceProduct)
            //       // get prorate sebelumnya


            //       console.log(this.priceProduct,'price product')

            //   }else{
            //     console.log('tgl tdk valid')
            //   }

            // }else if (this.order.id_order == null && this.ifOrder == false){
            //   console.log(this.order.id_order,'order baru')
            // }
              this.priceProduct = productData.price * this.order.total_month;
              this.priceProduct = this.priceProduct - this.discForProduct
          } else {
            this.priceProduct = 0
          }
        }
        // this.totalPrice = parseInt(this.priceProduct) + parseInt(this.priceUserPackage) + parseInt(this
        //   .priceStoragePackage) + parseInt(this.priceExtraPackage)

        this.calculatePrice();

      },

      async getUserPackagePrice() {
        if (typeof this.order.id_user_package !== "undefined") {
          
          let userPackageData = await this.getUserPackage(this.order.id_user_package);
          console.log(this.order.id_order,'id order')
          this.priceUserPackage = 0
          console.log(this.ifOrder,'if order')
          if (userPackageData.price !== "") {
            if(this.order.id_order !== null && this.ifOrder == true){
              // var dateNow = moment();
              // var endDate = moment(this.order.end_period).add(1,'days');
              console.log(this.order.id_order , 'order lama')


              if(this.dateNow < this.endDate){


                // console.log(userPackageData.price,'pricee user')
                // console.log(this.order.qty_user_package,'qty user')
                // console.log(this.order.total_month,'total month')
                // console.log(this.totalDaysPrevOrder, 'total hari sebelumnya')
                // console.log(this.sisaHari, 'sisa hari')
                // console.log((userPackageData.price * this.order.qty_user_package) * this.order.total_month)
                // console.log(this.totalDaysPrevOrder * this.sisaHari)
                // console.log(((parseInt(userPackageData.price) * parseInt(this.order.qty_user_package)) * parseInt(this.order.total_month)) / parseInt(this.totalDaysPrevOrder) * parseInt(this.sisaHari))

                this.priceUserPackage = Math.ceil(((userPackageData.price * this.order.qty_user_package) * this.order.total_month) / this.totalDaysPrevOrder * this.sisaHari);
                this.priceUserPackage = this.priceUserPackage - this.order.disc_user_package - this.discForUserPackage;
              }else{
                console.log('tgl tdk valid')
              }

            }else if (this.order.id_order == null && this.ifOrder == false){
              console.log(this.order.id_order,'order baru')
              this.priceUserPackage = (userPackageData.price * this.order.qty_user_package) * this.order.total_month;
              this.priceUserPackage = this.priceUserPackage - this.order.disc_user_package - this.discForUserPackage;
            }
          } else {
            this.priceUserPackage = 0 * this.order.qty_user_package;
            this.priceUserPackage = this.priceUserPackage - this.order.disc_user_package - this.discForUserPackage;
          }
        }
        // this.totalPrice = parseInt(this.priceProduct) + parseInt(this.priceUserPackage) + parseInt(this
        //   .priceStoragePackage) + parseInt(this.priceExtraPackage)
        this.calculatePrice();

      },

      async getStoragePackagePrice() {

        if (typeof this.order.id_storage_package !== "undefined") {
          let storagePackageData = await this.getUserStorage(this.order.id_storage_package);
          // console.log(storagePackageData)
          this.priceStoragePackage = 0
          if (storagePackageData.price !== "") {
            if(this.order.id_order !== null && this.ifOrder == true){
              // var dateNow = moment();
              // var endDate = moment(this.order.end_period).add(1,'days');
              console.log(this.order.id_order , 'order lama')
              // this.totalDaysPrevOrder = this.totalDaysPrevOrder * 30
              console.log(this.totalDaysPrevOrder, 'total hari sebelumnya')

              if(this.dateNow < this.endDate){

                this.sisaHari = this.endDate.diff(this.dateNow, 'days');
                console.log(this.sisaHari, 'sisa hari')

                this.priceStoragePackage = this.priceStoragePackage + (Math.ceil(((storagePackageData.price * this.order.qty_storage_package) * this.order.total_month) / this.totalDaysPrevOrder * this.sisaHari));
                this.priceStoragePackage = this.priceStoragePackage - this.order.disc_storage_package
              }else{
                console.log('tgl tdk valid')
              }

            }else if (this.order.id_order == null && this.ifOrder == false){
              console.log(this.order.id_order,'order baru')
              this.priceStoragePackage = (storagePackageData.price * this.order.qty_storage_package) * this.order.total_month;
              this.priceStoragePackage = this.priceStoragePackage - this.order.disc_storage_package
            }
            // this.priceStoragePackage = (storagePackageData.price * this.order.qty_storage_package) * this.order.total_month;
          } else {
            this.priceStoragePackage = 0 * this.order.qty_storage_package;
            this.priceStoragePackage = this.priceStoragePackage - this.order.disc_storage_package
          }
        }
        // this.totalPrice = parseInt(this.priceProduct) + parseInt(this.priceUserPackage) + parseInt(this
        //   .priceStoragePackage) + parseInt(this.priceExtraPackage)

        this.calculatePrice();

      },

      async getExtraPackagePrice(k) {
        // console.log('get extra package price')
        // console.log()
        let id = this.order.extra_package[k].id_extra_package
        if (typeof id !== "undefined") {
          let extraPackageData = await this.getExtraPackage(id);
          // console.log(extraPackageData, 'extra package ')
          if (extraPackageData.price !== "") {

            this.order.extra_package[k].price_extra_package = extraPackageData.price
            this.order.extra_package[k].price_extra_package = this.order.extra_package[k].price_extra_package - this.order.extra_package[k].disc_extra_package

          } else {
            this.order.extra_package[k].price_extra_package = 0
            this.order.extra_package[k].price_extra_package = this.order.extra_package[k].price_extra_package - this.order.extra_package[k].disc_extra_package
          }
        }
        this.priceExtraPackage = this.order.extra_package[k].price_extra_package

        // this.priceExtraPackage = this.priceExtraPackage - this.order.extra_package[k].disc_extra_package
        // console.log(this.priceExtraPackage,'price Extra Package')

        // this.totalPrice = parseInt(this.priceProduct) + parseInt(this.priceUserPackage) + parseInt(this
        //   .priceStoragePackage) + parseInt(this.priceExtraPackage)
        this.calculatePrice();

      },

      async calculatePrice(){

        if (typeof this.id !== "undefined") {
          this.priceExtraPackage = 0
          if(this.order.extra_package.length != 0){
            var priceCharge = 0;

            for (let i = 0; i < this.order.extra_package.length; i++) {
              const element = this.order.extra_package[i];
                priceCharge += element.price_extra_package;
            }
        
          }
            this.priceExtraPackage = this.priceExtraPackage + priceCharge;

            // console.log(priceCharge, 'price extra charge')
          // console.log(this.priceExtraPackage, 'price extra charge')
          this.totalPrice =  parseInt(this.priceProduct) + parseInt(this.priceUserPackage) + parseInt(this.priceStoragePackage) + parseInt(this.priceExtraPackage) - parseInt(this.discForOrder)
          // console.log(this.totalPrice, 'total harga order baru')

          if(this.ifOrder == true){

            // console.log(this.totalHargaSebelumnya, 'total harga order sebelumnya')
            // // get prorate new order
            // console.log(this.totalDaysPrevOrder,'total days prev order')
            // console.log(this.hariTerpakai,' hari yg terpakai')
            // console.log(this.sisaHari,'sisa hari')
            this.totalProrateOrderBaru = Math.ceil((this.totalPrice /this.totalDaysPrevOrder) * this.sisaHari);
            // console.log(this.totalProrateOrderBaru,'prorate baru')
            this.totalProrateOrderBaru = this.totalProrateOrderBaru + this.totalProrateOrderLama
            // console.log(this.totalProrateOrderBaru, 'total prorate baru')
            this.sisaBayar = this.totalProrateOrderBaru - this.totalHargaSebelumnya
            // console.log(this.sisaBayar, 'sisa bayar')
          }
        }else{
          // console.log(typeof this.id,'ada id nya')

          if(this.order.extra_package.length != 0){
        // var priceCharge = 0;

            for (let i = 0; i < this.order.extra_package.length; i++) {
              const element = this.order.extra_package[i];
                priceCharge = priceCharge + element.price_extra_package;
            }
            // this.priceExtraPackage = this.priceExtraPackage + priceCharge;
        
          }
            // console.log(priceCharge, 'price extra charge')
          // console.log(this.priceExtraPackage, 'price extra charge')
          this.totalPrice = parseInt(this.priceProduct) + parseInt(this.priceUserPackage) + parseInt(this.priceStoragePackage) + parseInt(this.priceExtraPackage) - parseInt(this.discForOrder)
          // console.log(this.totalPrice, 'total harga order baru')

          if(this.ifOrder == true){

            // console.log(this.totalHargaSebelumnya, 'total harga order sebelumnya')
            // // get prorate new order
            // console.log(this.totalDaysPrevOrder,'total days prev order')
            // console.log(this.hariTerpakai,' hari yg terpakai')
            // console.log(this.sisaHari,'sisa hari')
            this.totalProrateOrderBaru = Math.ceil((this.totalPrice /this.totalDaysPrevOrder) * this.sisaHari);
            // console.log(this.totalProrateOrderBaru,'prorate baru')
            this.totalProrateOrderBaru = this.totalProrateOrderBaru + this.totalProrateOrderLama
            // console.log(this.totalProrateOrderBaru, 'total prorate baru')
            this.sisaBayar = this.totalProrateOrderBaru - this.totalHargaSebelumnya
            // console.log(this.sisaBayar, 'sisa bayar')
          }
        }
        // console.log(this.order.extra_package, 'test')
        
        
      },

      async getVoucherDiskon(){
        if(typeof this.order.id_master_diskon !== 'undefined'){
          if(typeof this.order.id_product_item !== 'undefined'){
            let productData = await this.getProductItem(this.order.id_product_item);
            var addOns = []
            for (let i = 0; i < productData.addOns.length; i++) {
              const element = productData.addOns[i];
              addOns.push(element.add_on[0])
              console.log(addOns, 'isi add on')

            }
          }
          let getVoucher = await this.getVoucher(this.order.id_master_diskon)

          let dateNow = moment().format('YYYY-MM-DD')
          if(moment(dateNow).isSameOrBefore(getVoucher.end_date)){
            if(getVoucher.is_all_module == 1){
              this.descVoucher = 'this voucher for your order.'
            }
            let nominals = getVoucher.nominal.split('|')
            let newNominal = nominals.join(' + ')
            let Rp = 'Rp. ' + getVoucher.nominal
            let persen = newNominal + '%'
            this.discForOrder = 0
            this.discForProduct = 0
            this.discForUserPackage = 0
            this.totalPrice = 0
            this.getPriceProduct()
            this.getUserPackagePrice()
            this.calculatePrice()
            if(getVoucher.is_all_module == 1){
              if(getVoucher.type_voucher == 0 ){
                this.descVoucher = 'Voucher bisa digunakan untuk Orderan ini. kamu mendapat potongan harga ' +Rp
                this.discForOrder =  getVoucher.nominal
              }else{
                this.descVoucher = 'Voucher bisa digunakan untuk Orderan ini. kamu mendapat potongan harga ' + persen
                let nominal = getVoucher.nominal.split('|')
                let priceAfterDisc1 = 0
                for (let i = 0; i < nominal.length; i++) {
                  const element = nominal[i];
                  if(i == 0){
                    this.discForOrder = (element / 100) * this.totalPrice
                    priceAfterDisc1 = this.totalPrice - this.discForOrder
                  }else if(i > 0){
                    this.discForOrder += (element / 100) * priceAfterDisc1
                  }
                  
                }
                // console.log(priceAfterDisc1 ,' priceafter disc')
                // console.log(this.discForOrder, 'discfor order')
                
              }
              this.calculatePrice()
            }else{
              
              if(getVoucher.id_product_item != '' && getVoucher.id_user_package == null && getVoucher.id_product_item != '0' && getVoucher.id_add_on == null){
                
                if(getVoucher.id_product_item == this.order.id_product_item){
                  if(getVoucher.type_voucher == 0 ){
                    this.descVoucher = 'kamu mendapat potongan harga produk sebanyak ' +Rp
                    this.discForProduct = getVoucher.nominal
                  }else{
                    this.descVoucher = 'kamu mendapat potongan harga produk sebanyak ' + persen
                    // let priceAfterDisc = (getVoucher.nominal / 100) * this.priceProduct;
                    // this.discForProduct = priceAfterDisc
                    let nominal = getVoucher.nominal.split('|')
                    let priceAfterDisc1 = 0
                    for (let i = 0; i < nominal.length; i++) {
                      const element = nominal[i];
                      if(i == 0){
                        this.discForProduct = (element / 100) * this.priceProduct
                        priceAfterDisc1 = this.priceProduct - this.discForProduct
                      }else if(i > 0){
                        this.discForProduct += (element / 100) * priceAfterDisc1
                      }
                      
                    }
                  }
                }else{
                  this.descVoucher = 'Sorry, Voucher diskon tidak bisa digunakan untuk product yg kamu pilih'
                }
                this.getPriceProduct()
                // forProductItem = true
                // forUserPackage = false
              }else if(getVoucher.id_user_package != '' && getVoucher.id_product_item == null && getVoucher.id_add_on == null && getVoucher.id_user_package != '0'){
                
                if(getVoucher.id_user_package == this.order.id_user_package) {
                  if(getVoucher.type_voucher == 0 ){
                    this.descVoucher = 'kamu mendapat potongan harga user package sebanyak ' +Rp
                    this.discForUserPackage = getVoucher.nominal
                  }else{
                    this.descVoucher = 'kamu mendapat potongan harga user package sebanyak ' + persen
                    // let priceAfterDisc = (getVoucher.nominal / 100) * this.priceUserPackage;
                    // this.discForUserPackage = priceAfterDisc
                    let nominal = getVoucher.nominal.split('|')
                    let priceAfterDisc1 = 0
                    for (let i = 0; i < nominal.length; i++) {
                      const element = nominal[i];
                      if(i == 0){
                        this.discForUserPackage = (element / 100) * this.priceUserPackage
                        priceAfterDisc1 = this.priceUserPackage - this.discForUserPackage
                      }else if(i > 0){
                        this.discForUserPackage += (element / 100) * priceAfterDisc1
                      }
                      
                    }
                  }
                }else{
                  this.descVoucher = 'Sorry, Voucher diskon tidak bisa digunakan untuk User Package yg kamu pilih'
                }
                this.getUserPackagePrice()
                // forProductItem = false
                // forUserPackage = true
              }else if(getVoucher.id_product_item == '0' && getVoucher.id_user_package == null && getVoucher.id_add_on == null){
                
                if(getVoucher.type_voucher == 0 ){
                  this.descVoucher = 'kamu mendapat potongan harga Product sebanyak ' + Rp
                  this.discForProduct = getVoucher.nominal
                }else{
                  this.descVoucher = 'kamu mendapat potongan harga Product sebanyak ' + persen
                  // let priceAfterDisc = (getVoucher.nominal / 100) * this.priceProduct;
                  // this.discForProduct = priceAfterDisc
                  let nominal = getVoucher.nominal.split('|')
                  let priceAfterDisc1 = 0
                    for (let i = 0; i < nominal.length; i++) {
                      const element = nominal[i];
                      if(i == 0){
                        this.discForProduct = (element / 100) * this.priceProduct
                        priceAfterDisc1 = this.priceProduct - this.discForProduct
                      }else if(i > 0){
                        this.discForProduct += (element / 100) * priceAfterDisc1
                      }
                      
                  }
                }
                this.getPriceProduct()
              }else if(getVoucher.id_user_package == '0' && getVoucher.id_product_item == null && getVoucher.id_add_on == null){
                
                if(getVoucher.type_voucher == 0 ){
                  this.descVoucher = 'kamu mendapat potongan harga user package sebanyak ' + Rp
                  this.discForUserPackage =  getVoucher.nominal
                }else{
                  this.descVoucher = 'kamu mendapat potongan harga user package sebanyak ' + persen
                  // let priceAfterDisc = (getVoucher.nominal / 100) * this.priceUserPackage;
                  // this.discForUserPackage =  priceAfterDisc
                  let nominal = getVoucher.nominal.split('|')
                  console.log(nominal.length, 'nominal length')
                  console.log(nominal, 'nominal ')
                  let priceAfterDisc1 = 0
                    for (let i = 0; i < nominal.length; i++) {
                      const element = nominal[i];
                      if(i == 0){
                        this.discForUserPackage = (element / 100) * this.priceUserPackage
                        priceAfterDisc1 = this.priceUserPackage - this.discForUserPackage
                        console.log('if 1 == 0 ')
                      }else if(i > 0){
                        this.discForUserPackage += (element / 100) * priceAfterDisc1
                        console.log('if 1 > 0 ')
                      }
                      
                    }
                }
                this.getUserPackagePrice()
              }else if(getVoucher.id_add_on != '' && getVoucher.id_product_item == null && getVoucher.id_add_on != '0' && getVoucher.id_user_package == null){
                
                for (let i = 0; i < addOns.length; i++) {
                  const element = addOns[i];
                  if(getVoucher.id_add_on == element.id) {
                    if(getVoucher.type_voucher == 0 ){
                      this.descVoucher = 'kamu mendapat potongan harga add on sebanyak ' +Rp
                      this.discForProduct = getVoucher.nominal
                    }else{
                      this.descVoucher = 'kamu mendapat potongan harga add on sebanyak ' + persen
                      // let priceAfterDisc = (getVoucher.nominal / 100) * this.priceUserPackage;
                      // this.discForProduct = priceAfterDisc
                      let nominal = getVoucher.nominal.split('|')
                      let priceAfterDisc1 = 0
                      for (let j = 0; j < nominal.length; j++) {
                        const el = nominal[j];
                        if(j == 0){
                          this.discForProduct = (el / 100) * element.price
                          priceAfterDisc1 = element.price - this.discForProduct
                        }else if(j > 0){
                          this.discForProduct += (el / 100) * priceAfterDisc1
                        }
                        
                      }
                    }
                  }else{
                    this.descVoucher = 'Sorry, Voucher diskon tidak bisa digunakan untuk add on yg kamu pilih'
                  }
                  
                }
                this.getPriceProduct()
              }
            }
              
          }else{
            this.discForOrder = 0
            this.discForProduct = 0
            this.discForUserPackage = 0
            this.totalPrice = 0
            this.getPriceProduct()
            this.getUserPackagePrice()
            this.calculatePrice()
            this.descVoucher = 'Sorry, Voucher diskon Expired!'
          }
          this.calculatePrice()
        }
        

      }
    },
    computed: {
      ...mapState("order", ["order", "orders"]),
      ...mapState("company", ["companies"]),
      ...mapState("userPackage", ["userPackages", "userPackages"]),
      ...mapState("masterVoucher", ["vouchers", "voucher"]),
      ...mapState("userStorage", ["userStorages", 'userStorage']),
      ...mapState("productItem", ["productItems", "productItem"]),
      ...mapState("extraPackage", ["extraPackages"]),
      ...mapState(["errors"]),
      ...mapState("addOn", ["addOns"]),



    },
    mounted() {
      this.init();
    },
  };
</script>

<style scoped>
  .set-height {
    height: 80px;
  }
</style>