import UserPackage from "../../../api/UserPackage";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getUserPackages = ({ commit }, payload) => {
    UserPackage.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_USER_PACKAGES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
export const searchUserPackages = ({ commit }, payload) => {
    UserPackage.search(payload)
        .then((response) => {
            commit("SET_USER_PACKAGES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getUserPackage = ({ commit }, payload) => {
    return UserPackage.show(payload)
        .then((response) => {
            commit("SET_USER_PACKAGE", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeUserPackage = ({ dispatch }, payload) => {
    UserPackage.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-user-package" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateUserPackage = ({ dispatch }, payload) => {
    UserPackage.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-user-package" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteUserPackage = ({ commit }, payload) => {
    UserPackage.delete(payload)
        .then(() => {
            commit("UNSET_USER_PACKAGE", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};