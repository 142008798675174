<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-5 text-h5 font-weight-light">Form Jobfile Other Type</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-jobfile-other-type'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <v-form @submit.prevent="!id ? storeJobfileOtherType(jobfileOtherType) : updateJobfileOtherType(jobfileOtherType)">
          <v-container>
            <v-col>
              <v-row>
                <!-- Type Name -->
                <v-col cols="3">
                  <v-text-field label="Type Name" dense outlined class="" :error-messages="errors.type_name"
                    v-model="jobfileOtherType.type_name"></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-autocomplete v-model="jobfileOtherType.reference_type" item-text="name" item-value="id"
                  :items="itemsReferenceType" outlined dense label="Reference Type">
                </v-autocomplete>
                </v-col>
                <v-col>
                  <!-- button submit -->
                  <div class="text-right">
                    <v-btn color="blue" type="submit" class="button-save">Save</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>

          </v-container>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {
  },
  data: () => ({
    itemsReferenceType: [{
      'id': 'FCL',
      'name': 'FCL'
    }, {
      'id': 'LCL',
      'name': 'LCL'
    }, {
      'id': 'BREAKBULK',
      'name': 'BREAKBULK'
    }, {
      'id': 'RORO',
      'name': 'RORO'
    }],

    breads: [
      {
        text: 'Master Jobfile Other Type',
        disabled: false,
        href: 'jobfile-other-types',
      },
      {
        text: 'Create Jobfile Other Type',
        disabled: true,
        href: 'jobfile-other-types/create',
      },
    ],
  }),
  props: {
    id: null,
  },

  methods: {
    ...mapActions("jobfileOtherType", ["getJobfileOtherType", "storeJobfileOtherType", "updateJobfileOtherType", "getJobfileOtherTypeNumber"]),
    init() {
      if (typeof this.id !== "undefined") {
        this.getJobfileOtherType(this.id);
      } else {
        this.$store.commit('jobfileOtherType/SET_JOBFILE_OTHER_TYPE', {});
      }
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState("jobfileOtherType", ["jobfileOtherType"]),
    ...mapState(["errors"]),
  },
};
</script>

<style scoped>
.text-code {
  width: 150px;
}

.text-address:focus {
  color: white;
}

.text-name {
  width: 350px;
}

.height {
  height: 50px;
}

/* .container{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
} */

.button-save {
  width: 150px;
  color: white;
  background-color: blue;
}

.button-save:hover {
  width: 150px;
  color: white;
}

/* #heading-title{
  background-color: #444D54;
  border-bottom: #EEEEEE solid 25px;
} */
</style>