export const SET_USER_STORAGES = (state, payload) => {
    state.userStorages = payload;
};

export const SET_USER_STORAGE = (state, payload) => {
    state.userStorage = payload;
};

export const UNSET_USER_STORAGE = (state, payload) => {
    state.userStorages.data = state.userStorages.data.filter((item) => {
        return item.id !== payload;
    });
};