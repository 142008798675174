import Group from "../../../api/Group";

export const searchGroups = ({ commit }, payload) => {
    Group.search(payload)
        .then((response) => {
            commit("SET_GROUPS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
