<template>
  <v-app>
    <!-- navbar -->
    <web-navigation></web-navigation>

    <!-- main content -->
    <v-container fill-height fluid>
      <v-col cols="12">
        <v-row justify="center" align="center">
          <v-col cols="6">
            <v-img
              class="mx-auto"
              max-height="400"
              max-width="700"
              width="500px"
              src="../../assets/svg/home.svg"
            ></v-img>
          </v-col>
          <v-col cols="6">
            <h1 class="text-center">Hello world hellow duniawi!</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-container>

    <!-- footer -->
    <web-footer></web-footer>
  </v-app>
</template>

<script>
import WebNavigation from '../../components/admin/WebNavigation.vue'
import WebFooter from '../../components/admin/WebFooter.vue'

export default {
  name: 'Home',
  components: {
    WebNavigation,
    WebFooter
  }
}
</script>
