import RefModule from "../../../api/RefModule";
// import router from "../../../router";

export const getRefModules = ({ commit }, payload) => {
    RefModule.all(payload)
        .then((response) => {
            commit("SET_REF_MODULES", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchRefModules = ({ commit }, payload) => {
    RefModule.search(payload)
        .then((response) => {
            commit("SET_REF_MODULES", response.data.data);
            console.log(response.data.data,'get module')
        })
        .catch((error) => {
            console.log(error);
        });
};