import JobfileOtherType from "../../../api/JobfileOtherType";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getJobfileOtherTypes = ({ commit }, payload) => {
    JobfileOtherType.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_JOBFILE_OTHER_TYPES", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchJobfileOtherType = async({ commit }, payload) => {
    return await JobfileOtherType.search(payload)
        .then((response) => {
            commit("SET_JOBFILE_OTHER_TYPES", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getJobfileOtherType = ({ commit }, payload) => {
    JobfileOtherType.show(payload)
        .then((response) => {
            commit("SET_JOBFILE_OTHER_TYPE", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeJobfileOtherType = ({ dispatch }, payload) => {
    JobfileOtherType.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-jobfile-other-type" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateJobfileOtherType = ({ dispatch }, payload) => {
    // console.log(payload)
    JobfileOtherType.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-jobfile-other-type" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteJobfileOtherType = async({ commit }, payload) => {
    return await JobfileOtherType.delete(payload)
        .then((res) => {

            commit("UNSET_JOBFILE_OTHER_TYPE", payload);
            return res;
        })
        .catch((error) => {
            console.log(error);
        });
};