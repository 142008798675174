<template>
  <v-main>
    <v-container fluid>
      <v-row align="center" justify="space-between">
          <!-- Title -->
          <!-- <v-card-title>
            <h2 class="text-h5 font-weight-light">Welcome!</h2>
          </v-card-title> -->
          <!-- Add Button -->
          <!-- <v-card-title>
            <v-select
              label="Last 30 days"
            ></v-select>
          </v-card-title> -->
        </v-row>
      <!-- Content -->
      <v-card color="white" class="py-16 mt-10">
        <v-row align="center" justify="center" class="mt-0">
          
            <v-col cols="6">
              <v-img
                class="mx-auto"
                max-height="400"
                max-width="700"
                width="400px"
                src="../../../assets/svg/home.svg"
              ></v-img>
            </v-col>


            <v-col cols="6">
              <h1 class="text-h4 font-weight-light">Welcome to <br> Dashboard page!</h1>
            </v-col>
          




            <!-- User -->
            <!-- <v-col cols="4">
              <v-card
                class="mx-auto justify-center"
                color="#222529"
                dark
                height="250"
                max-width="400"
              >
                <v-card-title>
                  <v-list-item class="grow">
                    <v-list-item-avatar class="logo-center mx-auto" height="60" width="60" color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      ></v-img>
                    </v-list-item-avatar>
                   
                  </v-list-item>
                </v-card-title>
                <v-card-text class="text-h4 text-center font-weight-bold" id="height-total">
                  22.1 K
                </v-card-text>
                <v-card-text class="text-subtitle-2 text-center font-weight-bold" id="height-total-text">
                  Total Users
                </v-card-text>
                <v-card-text class="text-subtitle-2 text-center green--text font-weight-bold">
                  <v-icon>mdi-arrow-up-drop-circle-outline</v-icon> 40 %
                </v-card-text>
              </v-card>
            </v-col> -->

            <!-- Company -->
            <!-- <v-col cols="4">
              <v-card
                class="mx-auto justify-center"
                color="#222529"
                dark
                height="250"
                max-width="400"
              >
                <v-card-title>
                  <v-list-item class="grow">
                    <v-list-item-avatar class="logo-center mx-auto" height="60" width="60" color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      ></v-img>
                    </v-list-item-avatar>
                   
                  </v-list-item>
                </v-card-title>
                <v-card-text class="text-h4 text-center font-weight-bold" id="height-total">
                  26.1 K
                </v-card-text>
                <v-card-text class="text-subtitle-2 text-center font-weight-bold" id="height-total-text">
                  Total Company
                </v-card-text>
                <v-card-text class="text-subtitle-2 text-center green--text font-weight-bold">
                  <v-icon>mdi-arrow-up-drop-circle-outline</v-icon> 234 %
                </v-card-text>
              </v-card>
            </v-col> -->

            <!-- Order -->
            <!-- <v-col cols="4">
              <v-card
                class="mx-auto justify-center"
                color="#222529"
                dark
                height="250"
                max-width="400"
              >
                <v-card-title>
                  <v-list-item class="grow">
                    <v-list-item-avatar class="logo-center mx-auto" height="60" width="60" color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      ></v-img>
                    </v-list-item-avatar>
                   
                  </v-list-item>
                </v-card-title>
                <v-card-text class="text-h4 text-center font-weight-bold" id="height-total">
                  26.1 K
                </v-card-text>
                <v-card-text class="text-subtitle-2 text-center font-weight-bold" id="height-total-text">
                  Total Order
                </v-card-text>
                <v-card-text class="text-subtitle-2 text-center red--text font-weight-bold">
                  <v-icon>mdi-arrow-down-drop-circle-outline</v-icon> 2 %
                </v-card-text>
              </v-card>
            </v-col> -->
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions} from "vuex";
export default {
    data () {
    return {
      page : 1 ,
      // speed dial button
      hover: 'false',
      fab: false,
      direction: 'right',
      transition: 'slide-x-transition',
      breads: [
        {
          text: 'User',
          disabled: false,
          href: 'users',
        },
        {
          text: 'Create user',
          disabled: false,
          href: 'users/create',
        },
      ],
      search: '',
      searchUser: '',
    }
  },
  methods:{
    ...mapActions("user", ["getUsers", "deleteUser"]),
    getData(){
      this.getUsers({page: this.page, search : this.search})
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
/* .logo-center{

} */
#height-total{
  height: 20px;
}
#height-total-text{
  height: 30px;
}
</style>