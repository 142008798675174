<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-2 ma-4 mt-5">
        <v-row class="py-3 pb-5" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-2 text-h5 font-weight-light">Form User</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="mr-2 blue-grey darken-1" elevation="2" class="white--text" fab small
              :to="{name: 'admin-user'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? storeUser(user) : updateUser(user)">
            <v-container>
              <v-row>
                <!-- id company -->
                <v-col cols="4">
                  <v-autocomplete dense outlined persistent-hint :items="companies" item-value="id"
                    :error-messages="errors.id_company" label="Select Company" v-model="user.id_company"
                    :search-input.sync="searchCompany" @change="getDataSelect"></v-autocomplete>
                </v-col>
                <!-- default branch -->
                <v-col cols="4">
                  <v-autocomplete dense outlined :items="branchs" item-value="id" label="Select Branch"
                    :error-messages="errors.branchs_id" v-model="user.branchs_id" :search-input.sync="searchBranch"
                    multiple chips></v-autocomplete>
                </v-col>
                <!-- id group -->
                <v-col cols="4">
                  <v-autocomplete dense outlined :items="rbacRoles" item-value="id" label="Select Group"
                    :error-messages="errors.user_roles_id" v-model="user.user_roles_id" :search-input.sync="searchGroup"
                    multiple chips></v-autocomplete>
                </v-col>
                <!-- employee id -->
                <v-col cols="6" class="set-height">
                  <v-text-field dense outlined label="Employee ID" :error-messages="errors.employee_id"
                    v-model="user.employee_id"> </v-text-field>
                </v-col>
                <!-- email -->
                <v-col cols="6" class="set-height">
                  <v-text-field dense outlined label="Email" :error-messages="errors.username_user"
                    v-model="user.username_user"></v-text-field>
                </v-col>
                <!-- name -->
                <v-col cols="6" class="set-height">
                  <v-text-field dense outlined label="Name" v-model="user.name_user"></v-text-field>
                </v-col>
                <!-- phone number -->
                <v-col cols="6" class="set-height">
                  <v-text-field type="number" dense outlined label="Phone Number" :error-messages="errors.mobile_user"
                    v-model="user.mobile_user"></v-text-field>
                </v-col>


                <!--                &lt;!&ndash; password &ndash;&gt;-->
                <!--                <v-col cols="6" class="set-height">-->
                <!--                  <v-text-field dense outlined type="password" label="Password" :error-messages="errors.password_user" v-model="user.password_user"></v-text-field>-->
                <!--                </v-col>-->
                <!--                &lt;!&ndash; confirm password &ndash;&gt;-->
                <!--                <v-col cols="6" class="set-height">-->
                <!--                  <v-text-field dense outlined type="password" label="Confirm Password" :error-messages="errors.cpassword" v-model="user.cpassword"></v-text-field>-->
                <!--                </v-col>-->
              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>


import { mapActions, mapState } from "vuex";

export default {
  components: {},
  data: () => ({
    id_group: 0,
    id_company: 0,
    branchs_id: [],
    user_roles_id: [],
    default_branch: 0,
    employee_id: "",
    username_user: "",
    name_user: "",
    mobile_user: "",
    password_user: "",
    cpassword: "",
    searchGroup: "",
    searchCompany: "",
    searchBranch: "",
    searchRole: "",
    breads: [
      {
        text: 'User',
        disabled: false,
        href: 'users',
      },
      {
        text: 'Create user',
        disabled: true,
        href: 'users/create',
      },
    ],
  }),
  props: {
    id: null,
  },
  watch: {
    searchCompany: function () {
      this.companyDataSearch(this.searchCompany).then(() => {
        this.groupDataSearch(this.searchGroup);
        this.branchDataSearch(this.searchBranch);
        this.roleDataSearch(this.searchRole);
      })

    },

  },
  methods: {
    ...mapActions("user", ["getUser", "storeUser", "updateUser"]),
    async init() {
      if (typeof this.id !== "undefined") {
        let user = await this.getUser(this.id)
        console.log(user)
      } else {
        this.$store.commit('user/SET_USER', {});
      }
    },
    getDataSelect() {
      this.groupDataSearch(this.searchGroup);
      this.branchDataSearch(this.searchBranch);
      this.roleDataSearch(this.searchRole);

    },
    async companyDataSearch(data) {
      let params = {
        name: data
      }
      await this.$store.dispatch("company/searchCompanies", params)
    },
    groupDataSearch(data) {
      let params = {
        name: data,
        company_id: this.user.id_company
      }
      this.$store.dispatch("rbacRole/searchRbacRoles", params)
    },
    branchDataSearch(data) {

      let params = {
        name: data,
        company_id: this.user.id_company
      }
      this.$store.dispatch("branch/searchBranchs", params)
    },
    roleDataSearch(data) {
      let params = {
        name: data,
        company_id: this.user.id_company
      }
      this.$store.dispatch("role/searchRoles", params)
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("company", ["companies"]),
    ...mapState("rbacRole", ["rbacRoles"]),
    ...mapState("branch", ["branchs"]),
    ...mapState("role", ["roles"]),
    ...mapState(["errors"]),
  },
};
</script>

<style scoped>
.set-height {
  height: 80px;
}

.set-height-first {
  /* border-bottom: gray solid 1px; */
  height: auto;
}
</style>