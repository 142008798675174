export const SET_RBAC_ROLES = (state, payload) => {
    state.rbacRoles = payload;
};

export const SET_RBAC_ROLE = (state, payload) => {
    state.rbacRole = payload;
};

export const UNSET_RBAC_ROLE = (state, payload) => {
    state.rbacRoles = state.rbacRoles.filter((item) => {
        return item.id !== payload;
    });
};