export const SET_JOBFILE_OTHER_TYPES = (state, payload) => {
    state.jobfileOtherTypes = payload;
};

export const SET_JOBFILE_OTHER_TYPE = (state, payload) => {
    state.jobfileOtherType = payload;
};

export const UNSET_JOBFILE_OTHER_TYPE = (state, payload) => {
    state.jobfileOtherTypes.data = state.jobfileOtherTypes.data.filter((item) => {
        return item.id !== payload;
    });
};