export const SET_REF_MODULES = (state, payload) => {
    state.refModules = payload;
};

export const SET_REF_MODULE = (state, payload) => {
    state.refModule = payload;
};

export const UNSET_REF_MODULE = (state, payload) => {
    state.refModules.data = state.refModules.data.filter((item) => {
        return item.id !== payload;
    });
};