import AddOn from "../../../api/AddOn";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getAddOns = ({ commit }, payload) => {
    AddOn.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_ADD_ONS", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchAddOns = ({ commit }, payload) => {
    AddOn.search(payload)
        .then((response) => {
            commit("SET_ADD_ONS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getAddOn = async({ commit }, payload) => {
    return AddOn.show(payload)
        .then((response) => {
            commit("SET_ADD_ON", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeAddOn = ({ dispatch }, payload) => {
    AddOn.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-add-on" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateAddOn = ({ dispatch }, payload) => {
    AddOn.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-add-on" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteAddOn = ({ commit }, payload) => {
    AddOn.delete(payload)
        .then(() => {
            commit("UNSET_ADD_ON", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};