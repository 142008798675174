<template>
    <div>
        <!-- Sidebar -->
        <v-card
            height="100%"
            width="256"
            tile
            class=""
        >
          <v-navigation-drawer
            app
            mobile-breakpoint="960"
            width="260"
            v-model="drawer"
            :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
            :expand-on-hover="expandOnHover"
            :src="sideIcon"
            color="rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)1"
            v-bind="$attrs"
          >

          <template v-slot:img="props">
            <v-img
              :gradient="`to bottom, ${barColor}`"
              v-bind="props"
            />
          </template>

          <v-divider class="mb-1" />
          
            <v-list-item>
                <v-list-item-content class="mb-1">
                  <v-list-item-title class="title">
                    Administrator
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Version 1.0
                  </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
              dense
              nav
              >
              <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="{ name: item.url }"
              >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="font-weight-light">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
        <!-- End Sidebar -->

        <!-- App Bar -->
        <v-app-bar
          app
          color="white"
          absolute
          height="75"
        >
          <!-- Left Menu App Bar -->
          <v-btn
            color="transparent"
            class="mr-3 grey--text"
            elevation="0"
            fab
            small
            @click="setDrawer(!drawer)"
          >
            <v-icon>
                mdi-format-list-bulleted-square
            </v-icon>
          </v-btn>

          <!-- Title Navigation -->
          <v-toolbar-title
            class="hidden-sm-and-down font-weight-light text-capitalize"
          />

          <!-- Spacer -->
          <v-spacer />

          <!-- Right Menu App Bar -->
          <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition"
          >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-power</v-icon>
            </v-btn>
          </template>
                  <v-list
                    :tile="false"
                    nav
                  >
                  <div>
                      <v-list-item-title>
                          <v-btn
                            color="error"
                            small
                            class="text-capitalize"
                            @click="confirm"
                          >
                            Logout
                          </v-btn>
                      </v-list-item-title>
                  </div>
              </v-list>
          </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import { 
    mapState,
    mapMutations,
    mapActions 
  } from 'vuex'

export default {
    components: {

    },
    data: () => ({
        sideIcon: "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
        items: [
            {
              icon: 'mdi-view-dashboard',
              title: 'Dashboard',
              url: 'admin-dashboard',
            },
            {
              icon: 'mdi-city',
              title: 'Company',
              url: 'admin-company',
            },
            {
              icon: 'mdi-account',
              title: 'User',
              url: 'admin-user',
            },
            {
              icon: 'mdi-package-variant',
              title: 'Extra Package',
              url: 'admin-extra-package',
            },
            {
              icon: 'mdi-package',
              title: 'User Package',
              url: 'admin-user-package',
            },
            {
              icon: 'mdi-memory',
              title: 'User Storage',
              url: 'admin-user-storage',
            },
            {
              icon: 'mdi-cart-outline',
              title: 'Order',
              url: 'admin-order',
            },
            {
              icon: 'mdi-format-list-checks',
              title: 'Release Note',
              url: 'admin-release-note',
            },
            {
              icon: 'mdi-car',
              title: 'Product Item',
              url: 'admin-product-item',
            },
            {
              icon: 'mdi-package-variant',
              title: 'Add On',
              url: 'admin-add-on',
            },
            {
              icon: 'mdi-package',
              title: 'Master Voucher',
              url: 'admin-master-voucher',
            },
            {
              icon: 'mdi-package',
              title: 'Master Packages',
              url: 'admin-master-packages',
            },
            {
              icon: 'mdi-package',
              title: 'Master Jobfile Other Type',
              url: 'admin-jobfile-other-type',
            },
            {
              icon: 'mdi-cog-outline',
              title: 'Setting',
              url: 'setting-background',
            },
        ],
    }),
    props: {
        expandOnHover: {
          type: Boolean,
          default: false,
        },
        value: {
          type: Boolean,
          default: false,
        }
    },
    computed: {
        ...mapState(['barColor', 'barImage', 'drawer']),
        drawer: {
            get () {
            return this.$store.state.drawer
            },
            set (val) {
            this.$store.commit('SET_DRAWER', val)
            },
        },
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      ...mapActions("auth", ["logout"]),
        confirm() {
          this.$swal({
            title: "Are you sure?",
            text: "All your session will be deleted",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Logout",
          }).then((result) => {
            if (result.value) {
              this.logout();
            }
          });
        },
      }),
    },
}
</script>

<style>

</style>