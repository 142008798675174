<template>
    <!-- App Wrapper -->
    <v-app>
      <!-- Navigation -->
      <admin-navigation></admin-navigation>

      <!-- Content Wrapper -->
      <router-view class="all"></router-view>
      <!-- Footer -->
      <web-footer></web-footer>
    </v-app>
</template>

<script>

import WebFooter from "@/components/admin/WebFooter";
import AdminNavigation from "@/components/admin/AdminNavigation";

export default {
  components: {
    WebFooter,
    AdminNavigation
  },
  data: () => ({
    drawer: false,
    group: null,
  }),

}
</script>

<style scoped>
  .all{
    background: #eeeeee;
  }
</style>