import ReleaseNote from "../../../api/ReleaseNote";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getReleaseNotes = ({ commit }, payload) => {
    ReleaseNote.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_RELEASE_NOTES", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchReleaseNotes = ({ commit }, payload) => {
    ReleaseNote.search(payload)
        .then((response) => {
            commit("SET_RELEASE_NOTES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const changeStatus = ({ dispatch }, payload) => {
    return ReleaseNote.changeStatus(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getReleaseNote = ({ commit }, payload) => {
    return ReleaseNote.show(payload)
        .then((response) => {
            commit("SET_RELEASE_NOTE", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeReleaseNote = ({ dispatch }, payload) => {
    ReleaseNote.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-release-note" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateReleaseNote = ({ dispatch }, payload) => {
    ReleaseNote.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-release-note" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteReleaseNote = ({ commit }, payload) => {
    ReleaseNote.delete(payload)
        .then(() => {
            commit("UNSET_RELEASE_NOTE", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};