import Order from "../../../api/Order";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getOrders = ({ commit }, payload) => {
    Order.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_ORDERS", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getOrder = ({ commit }, payload) => {
    return Order.show(payload)
        .then((response) => {
            commit("SET_ORDER", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getInvoiceOrder = ({ commit }, payload) => {
    return Order.invoice(payload)
        .then((response) => {
            commit("SET_ORDER", response.data.data);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchOrders = ({ commit }, payload) => {
    Order.search(payload)
        .then((response) => {
            commit("SET_ORDERS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getPaymentProof = async(payload, data) => {
    return await Order.payment_proof(data)
        .then((response) => {
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getInvoiceNumber = async(payload) => {
    return await Order.invoice_number(payload)
        .then((response) => {
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeOrder = ({ dispatch }, payload) => {
    Order.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-order" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const sendInv = ({ dispatch }, payload) => {
    Order.send_inv(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-order" });
            Swal.fire('Success', 'Inv berhasil dikirim!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const cancelOrder = ({ dispatch }, payload) => {
    Order.cancel_order(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-order" });
            Swal.fire('Success', 'Order berhasil dicancel!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateOrder = ({ dispatch }, payload) => {
    Order.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-order" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteOrder = ({ commit }, payload) => {
    Order.delete(payload)
        .then(() => {
            commit("UNSET_ORDER", payload);
        })
        .catch((error) => {
            console.log(error);
        });
};