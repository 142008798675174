export const SET_EXTRA_PACKAGES = (state, payload) => {
    state.extraPackages = payload;
};

export const SET_EXTRA_PACKAGE = (state, payload) => {
    state.extraPackage = payload;
};

export const UNSET_EXTRA_PACKAGE = (state, payload) => {
    state.extraPackages.data = state.extraPackages.data.filter((item) => {
        return item.id !== payload;
    });
};