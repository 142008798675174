export const SET_VOUCHERS = (state, payload) => {
    state.vouchers = payload;
};

export const SET_VOUCHER = (state, payload) => {
    state.voucher = payload;
};

export const UNSET_VOUCHER = (state, payload) => {
    state.vouchers.data = state.vouchers.data.filter((item) => {
        return item.id !== payload;
    });
};