<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-1" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Login background</h2>
            <!-- Add Button -->
          </v-card-title>
        </v-row>
        <v-row class="" align="center" justify="space-around">
          <h3 class="ml-5 font-weight-light">Input Background</h3>
          <v-file-input
            v-model="file"
            class="ml-3"
            type="file"
            id="file"
            ref="file"
            style="margin-right: 20px; padding-right: 20px"
            accept="image/*"
          ></v-file-input>
          <h3 class="ml-5 mr-3 font-weight-light">Button Color</h3>
          <v-input-colorpicker v-model="color" />
          <v-col>
            <v-text-field v-model="color" class="mr-4"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="" align="center" justify="space-around">
          <h3 class="ml-5 font-weight-light">Input Background Form</h3>
          <v-file-input
            v-model="backgroundForm"
            class="ml-3"
            type="file"
            id="backgroundForm"
            ref="backgorundForm"
            style="margin-right: 20px; padding-right: 20px"
            accept="image/*"
          ></v-file-input>
        </v-row>
        <v-row class="mt-5">
          <v-btn
            rounded
            elevation="1"
            color="info"
            dark
            medium
            class="text-capitalize font-weight-bold white--text ml-5 mb-5"
            @click="uploadFile()"
          >
            upload
          </v-btn>
        </v-row>
      </v-card>

      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-1" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Setting Mail</h2>
            <!-- Add Button -->
          </v-card-title>
        </v-row>
        <v-row class="py-3 pb-1" align="center">
          <v-col>
            <!-- <label class="font-weight-light"
              >This Function for send mail Invoice to Admin</label
            > -->
  
            <v-autocomplete dense outlined  persistent-hint :items="admins" item-value="id" :error-messages="errors.idAdmin" label="Select Email Admin" v-model="idAdmin" :search-input.sync="searchAdmin" multiple></v-autocomplete>
            <v-btn
            rounded
            elevation="1"
            color="info"
            dark
            medium
            class="text-capitalize font-weight-bold white--text ml-5 mb-5"
            @click="saveEmail()"
          >
            Save Email
          </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-1" align="center" justify="space-between">
          <!-- Title -->
          <v-card-title>
            <h2 class="text-h5 font-weight-light">Setting General</h2>
            <!-- Add Button -->
          </v-card-title>
        </v-row>
        <v-row class="py-3 pb-1" align="center">
          <v-col>
            <label class="font-weight-light"
              >This Function for Clear ALl User Session Login</label
            >

            <v-btn
              rounded
              elevation="1"
              color="info"
              dark
              class="text-capitalize font-weight-bold white--text ml-5"
              @click="sessionDelete()"
            >
              Clear All Session User
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InputColorPicker from "vue-native-color-picker";
import DeleteAllSession from "../../../api/Setting";
export default {
  name: "setting-background",
  mounted() {
    console.log("Component mounted.");
    this.initAdmin()
  },
  components: {
    "v-input-colorpicker": InputColorPicker,
   
  },
  data() {
    return {
      file: null,
      backgroundForm: null,
      color: "#0f4c81",
      idAdmin : [],
      searchAdmin : "",
    };
  },
  watch:{
    // searchAdmin : function (){
    //     this.adminDataSearch(this.searchAdmin).then(() =>{
    //   })

    // },
    searchAdmin: function (searchCompany) {
        let params = {
          name: searchCompany,
        };
        this.$store.dispatch("user/searchAdmin", params);
      },
  },
  computed: {
      ...mapState('user',["admins"]),
      ...mapState(["errors"]),
  },

  methods: {
    ...mapActions(["getBackground"]),
    ...mapActions('user',["getAdmin", "updateAdmin", "showAdmin" ]),
    
    uploadFile() {
      if (this.file == null) {
        this.$swal("Warning!", "Cannot upload empty file!", "warning");
      } else {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("backgroundForm", this.backgroundForm);
        formData.append("buttonColor", this.color);
        this.getBackground(formData).then(() => {
          this.$swal({
            title: "Uploaded!",
            text: "Background Uploaded",
            icon: "success",
            // showCancelButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: "#d33",
            confirmButtonText: "oke",
          })
            // setTimeout(window.location.reload(), 15000);
            .then(function () {
              location.reload();
            });
        });
      }
    },
    sessionDelete() {
      this.$swal({
        icon: "warning",
        title: "Are you sure ?",
        text: "This Delete All Session Users Login!",
        showCancelButton: true,
        confirmButtonText: "Oke",
      }).then((result) => {
        if (result.isConfirmed) {
          let data = DeleteAllSession.destroy_session();
          console.log(data);
          this.$swal("Clear All Sessions!", "", "success");
        }
      });
    },
    saveEmail() {
      this.updateAdmin(this.idAdmin)
    },
    async initAdmin() {
      let admin = await this.showAdmin()
      console.log(admin.data.data,'this admin')
      for (let i = 0; i < admin.data.data.length; i++) {
        const element = admin.data.data[i];
        this.idAdmin.push(element.id)
      }
    }
  },
};
</script>

<style scoped>
.thead {
  background-color: #eeeeee;
}

.action-width {
  justify-content: left;
}
.search {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.relative {
  position: relative;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
