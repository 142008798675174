export const SET_RELEASE_NOTES = (state, payload) => {
    state.releaseNotes = payload;
};

export const SET_RELEASE_NOTE = (state, payload) => {
    state.releaseNote = payload;
};

export const UNSET_RELEASE_NOTE = (state, payload) => {
    state.releaseNotes.data = state.releaseNotes.data.filter((item) => {
        return item.id !== payload;
    });
};