import Branch from "../../../api/Branch";

export const searchBranchs = ({ commit }, payload) => {
    Branch.search(payload)
        .then((response) => {
            commit("SET_BRANCHS", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
