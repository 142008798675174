<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container>

      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breads">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <!-- Content -->
      <v-card max-width="100%" class="px-4 ma-4 mt-5">
        <v-row class="py-3 pb-2" align="center" justify="space-between">
          <!-- Title Left -->
          <v-card-title>
            <h2 class="ml-2 text-h5 font-weight-light">Form User Storage</h2>
          </v-card-title>
          <!-- Button back -->
          <v-card-title>
            <v-btn color="blue-grey darken-1" elevation="2" class="white--text" fab small :to="{name: 'admin-user-storage'}">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-title>
        </v-row>
        <!-- Form -->
        <div>
          <v-form @submit.prevent="!id ? store() : update()">
            <v-container>
              <v-row>
                <!-- name -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field dense outlined label="Nama" :error-messages="errors.name" v-model="userStorage.name" ></v-text-field>
                </v-col>
                <!-- storage -->
                <v-col cols="3" class="set-height mt-3">
                  <v-text-field type="number" dense outlined label="Storage" :error-messages="errors.storage" v-model="userStorage.storage"></v-text-field>
                </v-col>
                <!-- type -->
                <v-col cols="3" class="set-height mt-3">
                  <v-select
                    v-model="userStorage.type"
                    :items="items"
                    label="Type"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <!-- price -->
                <v-col cols="3" class="set-height mt-3">
                  <vuetify-money
                    v-model="userStorage.price"
                    label="Price"
                    placeholder="Input price"
                    :readonly="false"
                    :disabled="false"
                    :outlined="false"
                    :clearable="false"
                    :dense="true"
                    :options="options1"
                  />
                </v-col>
                <!-- is Free -->
                <v-col cols="3" class="">
                  <v-checkbox dense label="Is Free" :error-messages="errors.is_free" v-model="userStorage.is_free">
                  </v-checkbox>
                </v-col>
                <!-- description -->
                <v-col  cols="12" class="set-height-q">
                  <p>Description :</p>
                  <quill-editor v-model="userStorage.description" :error-messages="errors.description" />
                </v-col>
                

              </v-row>
              <div class="text-right">
                <v-btn color="cyan" type="submit" class="white--text font-weight-light ma-4 text-capitalize">Save</v-btn>
              </div>
            </v-container>
          </v-form>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
// import { Datetime } from 'vue-datetime';
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
    // datetime: Datetime
  },
  data: () => ({
    options1: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 12,
      precision: 0
    },
    items: ["MB","GB","TB"],
    value: ["MB","GB","TB"],
    date_schedule: "",
    version : "",
    description : "",
    breads: [
      {
        text: 'User Storage',
        disabled: false,
        href: 'user-storages',
      },
      {
        text: 'Create User Storage',
        disabled: true,
        href: 'user-storages/create',
      },
    ],
  }),
  props: {
    id: null,
  },
  watch: {
    
  },
  methods: {
    ...mapActions("userStorage", ["getUserStorage","storeUserStorage","updateUserStorage"]),
    async init(){
      if(typeof this.id !== "undefined"){
        await this.getUserStorage(this.id)
      } else {
        this.$store.commit('userStorage/SET_USER_STORAGE', {});
      }
    },
    store(){
      this.storeUserStorage(this.userStorage)
    },
    update(){
      this.updateUserStorage(this.userStorage)
    }
  },
  computed: {
    ...mapState("userStorage", ["userStorage"]),
    ...mapState(["errors"]),
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.set-height{
  height: 80px;
}

/* .set-height-q{
  height: 400px;
} */
</style>