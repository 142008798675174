export const SET_USER_PACKAGES = (state, payload) => {
    state.userPackages = payload;
};

export const SET_USER_PACKAGE = (state, payload) => {
    state.userPackage = payload;
};

export const UNSET_USER_PACKAGE = (state, payload) => {
    state.userPackages.data = state.userPackages.data.filter((item) => {
        return item.id !== payload;
    });
};