import Role from "../../../api/Role";

export const searchRoles = ({ commit }, payload) => {
    Role.search(payload)
        .then((response) => {
            commit("SET_ROLES", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};
