import Auth from '../../../api/Auth'
import router from '../../../router'

export const logout = () => {
    Auth.logout().then(() => {
        localStorage.removeItem('token');
        router.push({ name: 'admin-login' })
    }).catch(error => {
        console.log(error)
    })
}