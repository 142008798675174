import SettingBackground from "../../../api/SettingBackground";

export const getBackground = async(payload, data) => {
    await SettingBackground.setting_background(data)
        .then((response) => {
            console.log(response);
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getAdmin = async({ commit }, payload) => {
    return await SettingBackground.get_admin(payload)
        .then((response) => {
            console.log(response, 'get admin')
            commit("SET_ADMIN", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchAdmin = async({ commit }, payload) => {
    return await SettingBackground.search_admin(payload)
        .then((response) => {
            commit("SET_ADMIN", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};
